import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Card from "src/components/structure/Card";
import Screen from "src/components/structure/Screen";
import * as AppActions from "src/reducers/appReducer";
import { MessagesAPI, UserAPI } from "src/api";
import moment from "moment";
import { IMessage } from "src/api/messages";
import MessageListItem from "./MessageListItem";
import { Modal } from "react-bootstrap";
import { error, success } from "src/components/structure/Alert";

const df = "YYYY-MM-DD";

interface IMessagesScreenProps {
  appActions: any;
  userState: any;
  match: any;
}

interface IMessagesScreenState {
  loading: boolean;
  messages: IMessage[];
  filteredMessages: IMessage[];
  selectedMessage: IMessage | null;
  showViewModal: boolean;
  showReplyToModal: boolean;
  showArchiveModal: boolean;
  showNewModal: boolean;

  newSubject: string;
  newBody: string;
  newIncludeInBody: "yes" | "no";
  newConnection: any;

  connections: any[];
  selectedConnectionId: number;
  selectedConnectionDisplay: string,
}

class MessagesScreen extends React.Component<IMessagesScreenProps, IMessagesScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      messages: [],
      filteredMessages: [],
      selectedMessage: null,
      showViewModal: false,
      showReplyToModal: false,
      showArchiveModal: false,
      showNewModal: false,
      newSubject: "",
      newBody: "",
      newIncludeInBody: "no",
      newConnection: "",
      connections: [],
      selectedConnectionId: 0,
      selectedConnectionDisplay: "",
    };

    this.updateField = this.updateField.bind(this);
    this.setup = this.setup.bind(this);
    this.selectMessageForViewing = this.selectMessageForViewing.bind(this);
    this.selectMessageForArchive = this.selectMessageForArchive.bind(this);
    this.selectMessageForReply = this.selectMessageForReply.bind(this);
    this.toggleArchiveModal = this.toggleArchiveModal.bind(this);
    this.toggleReplyModal = this.toggleReplyModal.bind(this);
    this.toggleViewModal = this.toggleViewModal.bind(this);
    this.toggleNewModal = this.toggleNewModal.bind(this);

    this.create = this.create.bind(this);
    this.reply = this.reply.bind(this);
    this.archive = this.archive.bind(this);

    this.selectConnection = this.selectConnection.bind(this);
  }

  componentDidMount(){
    this.setup();
  }

  public render() {
    return (
      <Screen fileName="MessagesScreen.tsx">
        <div className="row">
          <div className="col-lg-2 col-md-12">
            <Card title="Connections" loading={this.state.loading} help="You can choose to filter on a connection or send a new message to a connection.">
              <div className="form-group">
                <select className="form-control" id="selectedConnectionId" value={this.state.selectedConnectionId} onChange={this.selectConnection}>
                  <option value={0}>All</option>
                  {this.state.connections.map((conn: any) => {
                    return <option key={conn.id} value={conn.providerId}>{conn.providerTitle} {conn.providerFirstName} {conn.providerLastName}</option>
                  })}
                </select>
              </div>
              <div className="form-group">
                {this.state.selectedConnectionId !== 0 && (
                  <button className="btn btn-block btn-primary" onClick={this.toggleNewModal}>New Message</button>
                )}
              </div>
            </Card>
          </div>
          <div className="col-lg-10 col-md-12">
            <Card title="Messages" loading={this.state.loading} help="Below, you will find any messages sent from your providers.">
              {this.state.messages.length === 0 && (
                <div className="row">
                  <div className="col-12">
                    <strong>None!</strong>
                  </div>
                </div>
              )}
              {this.state.filteredMessages.map((message) => {
                return (
                  <MessageListItem 
                    key={message.id} 
                    message={message} 
                    user={this.props.userState.user}
                    onMessageSelected={this.selectMessageForViewing}
                    onMessageArchived={this.selectMessageForArchive}
                    onMessageReplied={this.selectMessageForReply} />
                )
              })}
            </Card>
          </div>
        </div>

        <Modal show={this.state.showNewModal} onHide={this.toggleNewModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Create New Message</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Send To</label>
              <strong>{this.state.selectedConnectionDisplay}</strong>
            </div>
            <div className="form-group">
              <label>Subject</label>
              <input type="text" className="form-control" id="newSubject" value={this.state.newSubject} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Body</label>
              <textarea className="form-control" id="newBody" value={this.state.newBody} onChange={this.updateField} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-block btn-primary" onClick={this.create}>Send</button>
            <button className="btn btn-block btn-info" onClick={this.toggleNewModal}>Cancel</button>
          </Modal.Footer>
        </Modal>

        {this.state.selectedMessage && this.state.selectedMessage.id && (
          <div>
            <Modal show={this.state.showViewModal} onHide={this.toggleViewModal}>
              <Modal.Header closeButton={true}>
                <Modal.Title>View Message</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-group">
                  <p>From {this.state.selectedMessage.fromTitle} {this.state.selectedMessage.fromFirstName} {this.state.selectedMessage.fromLastName}</p>
                </div>
                <div className="form-group">
                  <p>Sent {moment(this.state.selectedMessage.sendDate).format("MM/DD/YYYY")}</p>
                </div>
                <div className="form-group">
                  <p>{this.state.selectedMessage.subject}</p>
                </div>
                <div className="form-group" style={{whiteSpace: "pre-wrap"}}>
                  {this.state.selectedMessage.body}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-block btn-primary" onClick={()=>{
                  this.setState({ showViewModal: false }, () => {
                    this.selectMessageForReply(this.state.selectedMessage);
                  });
                }}>Reply</button>
                <button className="btn btn-block btn-info" onClick={this.toggleViewModal}>Close</button>
              </Modal.Footer>
            </Modal>


            <Modal show={this.state.showReplyToModal} onHide={this.toggleReplyModal}>
              <Modal.Header closeButton={true}>
                <Modal.Title>Reply</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-group">
                  <p>To: {this.state.selectedMessage.fromTitle} {this.state.selectedMessage.fromFirstName} {this.state.selectedMessage.fromLastName}</p>
                </div>
                <div className="form-group">
                  <label>Subject</label>
                  <input type="text" className="form-control" id="newSubject" value={this.state.newSubject} onChange={this.updateField} />
                </div>
                <div className="form-group">
                  <label>Body</label>
                  <textarea className="form-control" id="newBody" rows={8} value={this.state.newBody} onChange={this.updateField} />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-block btn-primary" onClick={this.reply}>Send Reply</button>
                <button className="btn btn-block btn-info" onClick={this.toggleReplyModal}>Cancel</button>
              </Modal.Footer>
            </Modal>
          </div>
        )}
      </Screen>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private selectMessageForViewing(message: any){
    const messages: any[] = [];
    const filteredMessages: any[] = [];
    for(const m of this.state.messages){
      if(m.id === message.id){
        m.status = "viewed";
      }
      messages.push(m);
    }
    for(const m of this.state.filteredMessages){
      if(m.id === message.id){
        m.status = "viewed";
      }
      filteredMessages.push(m);
    }
    this.setState({
      messages,
      filteredMessages,
      selectedMessage: message,
      showViewModal: true,
    }, async () => {
      if(message.status === "sent"){
        try{
          await MessagesAPI.updateMessage(message.id, {status: "viewed" });
        }catch(err){}
      }
    });
  }

  private selectMessageForArchive(message: any){
    this.setState({
      selectedMessage: message,
      showArchiveModal: true,
    });
  }

  private selectMessageForReply(message: any){
    this.setState({
      selectedMessage: message,
      newSubject: "Re: " + message.subject,
      newBody: "\n-------------------------\n" + message.body,
      showReplyToModal: true,
    }, async () => {
      try{
        await MessagesAPI.updateMessage(message.id, {status: "viewed" });
      }catch(err){}
    });
  }


  private setup(){
    this.setState({loading: true}, async () => {
      try{
        const messageRes = await MessagesAPI.getMessages(moment().subtract(10, "years").format(df), moment().add(1, "days").format(df));
        const messages = messageRes.body.data;
        const connectionsRes = await UserAPI.getUserConnections();
        this.setState({loading: false, messages, filteredMessages: messages, connections: connectionsRes.body.data}, () => {
          // see if we need to select one from the url
          const messageId = this.props.match.params.messageId;
          if(messageId && messageId !== ""){
            const mId = parseInt(messageId);
            for(const m of messages){
              if(m.id === mId){
                this.selectMessageForViewing(m);
                break;
              }
            }
          }
        });
      }catch(error){
        this.setState({loading: false});
      }
    })
  }

  private toggleArchiveModal(){
    this.setState({ showArchiveModal: !this.state.showArchiveModal});
  }

  private toggleViewModal(){
    this.setState({ showViewModal: !this.state.showViewModal});
  }

  private toggleReplyModal(){
    this.setState({ showReplyToModal: !this.state.showReplyToModal});
  }

  private toggleNewModal(){
    this.setState({
      showNewModal: !this.state.showNewModal,
    });
  }

  private create(){
    const data = {
      toId: this.state.selectedConnectionId,
      subject: this.state.newSubject,
      body: this.state.newBody,
      replyToId: 0,
    };
    this.setState({ loading: true }, async () => {
      try{
        await MessagesAPI.createMessage(data);
        this.setState({
          loading: false,
          newSubject: "",
          newBody: "",
          showNewModal: false,
        }, () => this.setup());
        success("Message queued for delivery!")
      }catch(err){
        error("Could not send that message. Please contact support.");
        this.setState({ loading: false });
      }
    })
  }

  private reply(){
    const data = {
      toId: this.state.selectedMessage?.fromId,
      subject: this.state.newSubject,
      body: this.state.newBody,
      replyToId: this.state.selectedMessage?.id,
    };
    this.setState({ loading: true }, async () => {
      try{
        await MessagesAPI.createMessage(data);
        success("Reply sent!");
        this.setState({
          showReplyToModal: false,
          selectedMessage: null,
          newBody: "",
          newSubject: "",
          loading: false,
        })
      }catch(err){
        error("Could not send that reply");
      }
    })
  }

  private archive(){
    console.log(this.state);
  }

  private selectConnection(e: any){
    const id = parseInt(e.target.value);
    if(id === 0){
      // show all messages
      const filteredMessages = this.state.messages;
      this.setState({ selectedConnectionId: id, filteredMessages });
    } else {
      // filter
      const filteredMessages: any[] = [];
      for(const m of this.state.messages){
        if(m.fromId === id || m.toId === id){
          filteredMessages.push(m);
        }
      }
      let selectedConnectionDisplay = "";
      for(const c of this.state.connections){
        if(c.providerId === id){
          selectedConnectionDisplay = `${c.providerTitle ? c.providerTitle : ""} ${c.providerFirstName} ${c.providerLastName}`;
          break;
        }
      }
      this.setState({ selectedConnectionId: id, filteredMessages, selectedConnectionDisplay });
    }
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MessagesScreen) as any);
import * as incoming from "./incomingContact";
import * as journal from "./journal";
import * as messages from "./messages";
import * as remember from "./rememberJar";
import * as tools from "./tools";
import * as user from "./user";

export const IncomingContactAPI = new incoming.IncomingContactAPI();
export const JournalAPI = new journal.JournalAPI();
export const MessagesAPI = new messages.MessagesAPI();
export const RememberJarAPI = new remember.RememberJarAPI();
export const ToolsAPI = new tools.ToolsAPI();
export const UserAPI = new user.UserAPI();
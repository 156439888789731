import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

import Card from "src/components/structure/Card";
import Screen from "src/components/structure/Screen";
import * as AppActions from "src/reducers/appReducer";
import { ToolsAPI, UserAPI } from "src/api";
import { IUserToUserConnection } from "src/api/user";
import { Modal } from "react-bootstrap";
import { IToolRequest } from "src/api/tools";

interface IDashboardProps {
  appActions: any;
}

interface IDashboardState {
  loading: boolean;
  pendingConnections: IUserToUserConnection[];
  showConnectionsModal: boolean;

  lastLoggedIn: moment.Moment;
  created: moment.Moment;
  lastUpdated: moment.Moment;
  requests: IToolRequest[];
}

const requestHelp = `Your connections may request that you complete tools to help track and evaluate your care. If you have any requested tool submissions or assessments, they will be listed here.`;

class Dashboard extends React.Component<IDashboardProps, IDashboardState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      pendingConnections: [],
      showConnectionsModal: false,

      created: moment(),
      lastLoggedIn: moment(),
      lastUpdated: moment(),

      requests: [],
    };

    this.updateField = this.updateField.bind(this);
    this.setup = this.setup.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount(){
    this.setup();
  }

  public render() {
    return (
      <Screen fileName="Dashboard.tsx">
        <div className="row">
          <div className="col-lg-4 col-12">
            <div className="row">
              <div className="col-12">
                <Card title="News" loading={this.state.loading} help="">
                  <div className="form-group">
                    <strong>02/25/2022</strong> - Welcome to Voithos! We are in <strong>Open Beta</strong> as we expand the app from it's original form as ChapTrack. Please let us know if you run into any bugs or if you think of any helpful features you would like to see!
                  </div>
                </Card>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Card title="Stats" loading={this.state.loading} help="">
                  <div className="form-group">
                    <div>Last logged in on {moment(this.state.lastLoggedIn).format("MM/DD/YYYY")}</div>
                    <div>Last updated on {moment(this.state.lastUpdated).format("MM/DD/YYYY")}</div>
                    <div>Account created on {moment(this.state.created).format("MM/DD/YYYY")}</div>
                  </div>
                </Card>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-12">
            <Card title="Requested Tool Submissions" loading={this.state.loading} help={requestHelp}>
            {this.state.requests.length === 0 && (
                <div className="row">
                  <div className="col-12">
                    <p>No one has requested a submission from you.</p>
                  </div>
                </div>
              )}
              {this.state.requests.length !== 0 && (
                <div className="row row-header">
                  <div className="col-lg-3">
                    Requested From
                  </div>
                  <div className="col-lg-3">
                    Type
                  </div>
                  <div className="col-lg-2">
                    Due
                  </div>
                  <div className="col-lg-2">
                    Status
                  </div>
                  <div className="col-lg-2">
                    
                  </div>
                </div>
              )}
              {this.state.requests.map((r) => {
                return (
                  <div className="row" key={r.id}>
                    <div className="col-lg-3">
                      {r.providerFirstName} {r.providerLastName}
                    </div>
                    <div className="col-lg-3">
                      {ToolsAPI.translateToolTypeToDisplay(r.toolType)}
                    </div>
                    <div className="col-lg-2">
                      {moment(r.dueOn).format("MM/DD/YYYY")}
                    </div>
                    <div className="col-lg-2">
                      {r.status}
                    </div>
                    <div className="col-lg-2">
                      {r.status === "active" ? 
                      (<Link to={`/tools/${r.toolType}/new?requestId=${r.id}&providerId=${r.providerId}`}>Begin</Link>) :
                      (<Link to={`/tools/${r.toolType}/${r.id}`}>Review</Link>)}
                    </div>
                  </div>
                );
              })}
            </Card>
          </div>
        </div>

        <Modal show={this.state.showConnectionsModal} onHide={this.toggleModal} dialogClassName="modal-large" >
          <Modal.Header closeButton={true}>
            <Modal.Title>Pending Connections</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <p>You have pending invitations from:</p>
                <ul>
                  {this.state.pendingConnections.map((i: IUserToUserConnection) => {
                    return (
                      <li key={i.id}>{i.providerFirstName} {i.providerLastName}</li>
                    )
                  })}
                </ul>
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <a className="btn btn-block btn-primary" href="/connections">View Invitations</a>
            <button className="btn btn-block btn-default" onClick={this.toggleModal}>Later</button>
          </Modal.Footer>
        </Modal>
      </Screen>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private async setup(){
    // we want to first see if they have any connection requests; if so, we want to prompt that front and center
    try{
      const connectionsResult = await UserAPI.getUserConnections();
      const pendingConnections: IUserToUserConnection[] = [];
      let showConnectionsModal = false;
      for(const c of connectionsResult.body.data){
        if(c.status === "invited"){
          showConnectionsModal = true;
          c.created = moment(c.created);
          c.updated = moment(c.updated);
          pendingConnections.push(c);
        }
      }
      // get the profile
      const profileResult = await UserAPI.getUserProfile();
      const lastUpdated = moment(profileResult.body.data.lastUpdated);
      const created = moment(profileResult.body.data.created);
      const lastLoggedIn = moment(profileResult.body.data.lastLogin);
      
      // get the requests

      const requestsResult = await ToolsAPI.getRequests();
      const requests: IToolRequest[] = [];
      for(const r of requestsResult.body.data.asUser){
        r.dueOn = moment(r.dueOn);
        r.requestedOn = moment(r.requestedOn);
        requests.push(r);
      }

      this.setState({
        loading: false, 
        showConnectionsModal, 
        pendingConnections,
        lastLoggedIn,
        lastUpdated,
        created,
        requests,
      })
    }catch(err){
      this.setState({ loading: false });
    }
  }

  private toggleModal(){
    this.setState({showConnectionsModal: !this.state.showConnectionsModal});
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard) as any);
import * as React from "react";
import { connect } from "react-redux";
import { withRouter, Redirect, Link } from "react-router-dom";
import Screen from "src/components/structure/Screen";


const env = process.env.REACT_APP_ENV || "local";

class Home extends React.Component<any, any> {
  public render() {
    if (this.props.userState.loggedIn) {
      return <Redirect to="/dashboard" />;
    } else {
      return (
        <Screen>
          <div>
            <div className="row justify-content-center">
              <div className="col-12" style={{ textAlign: "center" }}>
                <img src="https://cdn.voithosapp.com/images/banner_transparent.png" alt="Voithos Logo" style={{ maxWidth: "50%" }} />
                <h3>The Modern App for Helping Care Providers and Teams</h3>
              </div>
            </div>
            {env === "demo" && (
              <div className="row">
                <div className="col-lg-6 offset-lg-3 col-sm-12" style={{
                  border: "thin solid black",
                  padding: 10,
                  backgroundColor: "#52514b"
                }}>
                  <p>Thank you for trying the Voithos demo account! This is the Client Portal. If you are looking for the Provider Portal, please go to <a href="https://demopro.voithosapp.com" target="_new">https://demopro.voithosapp.com</a>. This instance is <strong>wiped and restored daily with semi-randomized data.</strong> It is meant to give you an opportunity to explore many of the benefits Voithos can bring to your services. We have configured several different account configurations for you to try. Remember, this is a demonstration, and not all features are enabled. For example, actual emails and messages will not be sent to avoid abuse.</p>
                  <p>To get started, please <strong><Link to="/login" style={{textDecoration: "underline"}}>Login!</Link></strong></p>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-lg-6 offset-lg-3 col-sm-12" style={{fontSize: 18, textAlign: "justify"}}>
                Voithos is an integrated platform enabling care providers to track their efforts, clients, reports, and trends. Using modern technology, Voithos is an indispensible tool for Chaplains, Ministers, Mental Health Coaches, and Interventionists. Let us help you help others! It is completely online and provides activity tracking, client management, milestones, reminders, messaging, verbatims, and more!
              </div>
            </div>
            <div className="row justify-content-center" style={{ marginTop: 15 }}>
              <div className="col-lg-4 col-sm-12">
                <h3 style={{ textAlign: "center" }}>Easy to Use</h3>
                <p>Clean. Simple. Intuitive. We strive to have a great platform that enables you to be more effective. With a free tier that allows you to get up and running quickly, it is easy to quickly improve your ministry potential!</p>
              </div>
              <div className="col-lg-4 col-sm-12">
                <h3 style={{ textAlign: "center" }}>Secure</h3>
                <p>We use state of the art technology to ensure a safe, stable, and secure environment for tracking your ministry contacts, activities, notes, and messages.</p>
              </div>
              <div className="col-lg-4 col-sm-12">
                <h3 style={{ textAlign: "center" }}>Open to All</h3>
                <p>Although we focus on professional helpers, such as Chaplains, Counselors, and Mental Health Professionals, it is easy to adapt our software for almost any situation that requires tracking of activities, contacts, and messages. With a free tier, it's easy to evaluate if we will fit your needs!</p>
              </div>
            </div>
  
          </div>
        </Screen>
      );
    }
  }
}


const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home) as any);
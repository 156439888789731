import * as React from "react";
import { ToolsAPI } from "src/api";
import { error } from "src/components/structure/Alert";

interface IShareRowProps {
  connection: any;
  userId: number;
  submissionId: number;
  toolType: string;
  isShared?: boolean;
  shareId?: number;
}

interface IShareRowState {
  loading: boolean;
  isShared: boolean;
  name: string;
}

export class ShareRow extends React.Component<IShareRowProps, IShareRowState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      isShared: this.props.isShared? this.props.isShared : false,
      name: "",
    };

    this.share = this.share.bind(this);
  }

  componentDidMount(){
    const name = this.props.connection.providerId === this.props.userId ? 
    `${this.props.connection.userFirstName} ${this.props.connection.userLastName}` : 
    `${this.props.connection.providerFirstName} ${this.props.connection.providerLastName}`;
    this.setState({name});
  }

  public render() {
    return (
      <div className="row" key={this.props.connection.id}>
        <div className="col-lg-8 col-12">
          {this.state.name}
        </div>
        <div className="col-lg-4 col-12">
          {this.state.loading && (
            <div style={{textAlign: "center"}}>
              <div className="spinner-border spinner-border-small" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          {this.state.isShared && !this.props.shareId && (<span>Shared!</span>)}
          {!this.state.isShared && !this.state.loading && (<button className="btn btn-link" style={{padding: 0}} onClick={this.share}>Share</button>)}
        </div>
      </div>
    );
  }

  private share(){
    this.setState({loading: true}, async () => {
      try{
        await ToolsAPI.shareASubmission(this.props.toolType, this.props.submissionId, this.props.connection.providerId);
        this.setState({loading: false, isShared: true });
      }catch(err){
        error("Could not share that submission. Please contact support.")
        this.setState({loading: false});
      }
    });
  }

}

import { makeCall } from "./client";

export class IncomingContactAPI {
 
  public sendContact(name: string, reason: string, body: string, email: string, browserInformation: string = "", userId: number = 0, url: string = "", params: any = {}): Promise<any> {
    const data = {
      ...params,
      name,
      reason,
      body,
      email,
      browserInformation,
      userId,
      url,
    };
    return makeCall("post", `contact`, data);
  }

}
import * as React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import moment from "moment";
import { ToastContainer } from 'react-toastify';

import store from "src/store";

import Menu from "src/components/structure/Menu";
import Nav from "src/components/structure/Nav";

// get the screens
import Home from "src/components/screens/Home";
import Dashboard from "src/components/screens/Dashboard";
import Login from "src/components/screens/Login/Login";
import Signup from "src/components/screens/Login/Signup";
import Reset from "src/components/screens/Login/ResetPassword";
import ResetVerify from "src/components/screens/Login/ResetPasswordVerify";
import SignupVerify from "src/components/screens/Login/SignupVerify";

import Profile from "src/components/screens/User/Profile";

import ConnectionsScreen from "src/components/screens/UserConnections/Connections";

import ToolsDashboardScreen from "src/components/screens/Tools/Dashboard";
import NewToolScreen from "src/components/screens/Tools/NewTool";
import ToolView from "src/components/screens/Tools/ToolView";

import MessagesScreen from "src/components/screens/Messages/MessagesScreen";
import JournalScreen from "./components/screens/Journal/JournalScreen";
import RememberJarScreen from "./components/screens/RememberJar/RememberJarScreen";

class App extends React.Component {

  public render() {
    return (
      <Provider store={store}>
        <Router>
          <Menu>
            <div className="container-fluid">
              <ToastContainer />
              <Nav />
              <div>
                <div className="row" style={{marginTop: 20}}>
                  <div className="col-12 justify-content-center">
                    <main>
                      <Switch>
                        <Route exact={true} path="/" component={Home} />
                        <Route exact={true} path="/about" component={Home} />
                        <Route exact={true} path="/dashboard" component={Dashboard} />
                        <Route exact={true} path="/login" component={Login} />
                        <Route exact={true} path="/signup" component={Signup} />
                        <Route exact={true} path="/verify" component={SignupVerify} />
                        <Route exact={true} path="/reset" component={Reset} />
                        <Route exact={true} path="/reset/verify" component={ResetVerify} />

                        <Route exact={true} path="/me" component={Profile} />

                        <Route exact={true} path="/connections" component={ConnectionsScreen} />

                        <Route exact={true} path="/tools" component={ToolsDashboardScreen} />
                        <Route exact={true} path="/tools/:toolType/new" component={NewToolScreen} />
                        <Route exact={true} path="/tools/:toolType/:submissionId" component={ToolView} />

                        <Route exact={true} path="/messages" component={MessagesScreen} />
                        <Route exact={true} path="/messages/:messageId" component={MessagesScreen} />

                        <Route exact={true} path="/journal" component={JournalScreen} />
                        <Route exact={true} path="/remember" component={RememberJarScreen} />

                      </Switch>
                    </main>
                  </div>
                </div>

                <footer>
                  <div className="row" style={{marginTop: 20}}>
                    <div className="col-12" style={{ "textAlign": "center" }}>
                      <p className="small">Copyright 2010-{moment().format("YYYY")} <a href="https://www.kvsstechnologies.com" target="_new">KVSS Technologies, LLC</a> and <a target="_new" href="https://treelightsoftware.com">Treelight Software Inc</a></p>
                      <p className="small">Use of this application constitutes agreement with our <a href="/terms" target="_new">Terms of Service and Privacy Policy</a></p>
                    </div>
                  </div>
                </footer>
              </div>


            </div>
          </Menu>
        </Router>
      </Provider>
    );
  }
}

export default App;

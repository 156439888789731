import { createAction, createReducer } from "redux-act";

export const showMessage: any = createAction("show message");
export const hideMessage: any = createAction("hide message");
export const toggleHelp: any = createAction("app - toggle help");
export const toggleContact: any = createAction("app - toggle contact");
export const toggleMenu: any = createAction("app - toggle menu");
export const setMenu: any = createAction("app - set menu");
export const setDebug: any = createAction("app - set debug");

export default createReducer({
    [showMessage]: (state, payload) => {
      // type should be one of error, success, warning
      return {
        ...state, 
        message: {
          type: payload.type,
          message: payload.message
        }
      };
    },
    [hideMessage]: (state) => {
      return {
        ...state, 
        message: {
          type: "",
          message: ""
        }
      };
    },
    [toggleHelp]: (state) => {
      const show = !state.showHelp;
      return {
        ...state, 
        showHelp: show
      };
    },
    [toggleContact]: (state) => {
      const show = !state.showContact;
      return {
        ...state, 
        showContact: show
      };
    },
    [toggleMenu]: (state) => {
      const show = !state.showMenu;
      return {
        ...state, 
        showMenu: show
      };
    },
    [setMenu]: (state, menuOpen: boolean) => {
      const show = menuOpen;
      return {
        ...state, 
        showMenu: show
      };
    },
    [setDebug]: (state, debug: boolean) => {
      return {
        ...state, 
        debug
      };
    }
  }, {
    message: {
      type: "",
      message: "",
    },
    showHelp: false,
    showMenu: false,
    showContact: false,
    debug: false,
});
import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Card from "src/components/structure/Card";
import Screen from "src/components/structure/Screen";
import * as AppActions from "src/reducers/appReducer";
import { IUserToUserConnection } from "src/api/user";
import { UserAPI } from "src/api";
import { BlankUserToUserConnection } from "src/api/user";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { error, success } from "src/components/structure/Alert";

interface IConnectionsProps {
  appActions: any;
  userState: any;
}

interface IConnectionsState {
  loading: boolean;
  connections: IUserToUserConnection[];
  selectedConnection: IUserToUserConnection;
  showManageModal: boolean;
}

class Connections extends React.Component<IConnectionsProps, IConnectionsState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      connections: [],
      selectedConnection: BlankUserToUserConnection,
      showManageModal: false,
    };

    this.updateField = this.updateField.bind(this);
    this.setup = this.setup.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.getModalButtons = this.getModalButtons.bind(this);
    this.acceptConnection = this.acceptConnection.bind(this);
    this.declineConnection = this.declineConnection.bind(this);
  }

  componentDidMount(){
    this.setup();
  }

  public render() {
    return (
      <Screen fileName="Connections.tsx">
        <div className="row">
          <div className="col-lg-6 offset-lg-3 col-12">
            <Card title="User Connections" loading={this.state.loading} help="Here you can see all of the users you are connected to and manage those connections. Make sure you only connect with providers you trust and know!">
              <div className="row list-header">
                <div className="col-lg-4 col-md-4 col-12">
                  Name
                </div>
                <div className="col-lg-3 col-md-3 col-12">
                  Status
                </div>
                <div className="col-lg-3 col-md-3 col-12">
                  Your Role
                </div>
                <div className="col-lg-2 col-md-2 col-12">
                  
                </div>
              </div>
              {this.state.connections.map((c) => {
                return (
                  <div key={c.id} className="row list-item" style={{marginTop: 10}}>
                    <div className="col-lg-4 col-md-4">
                      {c.providerId === this.props.userState.user.id ? 
                        (<span>{c.userFirstName} {c.userLastName}</span>) : 
                        (<span>{c.providerFirstName} {c.providerLastName}</span>)}
                    </div>
                    <div className="col-lg-3 col-md-3">
                    {c.status}
                    </div>
                    <div className="col-lg-3 col-md-3">
                      {c.providerId === this.props.userState.user.id ? ("Provider") : ("Client")}
                    </div>
                    <div className="col-lg-2 col-md-2">
                      <button className="btn btn-link" style={{padding: 0}} onClick={() => {this.setState({selectedConnection: c, showManageModal: true})}}>Manage</button>
                    </div>
                  </div>
                )
              })}

              <Modal show={this.state.showManageModal} onHide={this.toggleModal} dialogClassName="modal-large" >
                <Modal.Header closeButton={true}>
                  <Modal.Title>Manage Connection</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p><strong>{this.state.selectedConnection.providerFirstName} {this.state.selectedConnection.providerLastName}</strong> has requested that <strong> {this.state.selectedConnection.userFirstName} {this.state.selectedConnection.userLastName}</strong> connect as their <strong>client.</strong>
                  </p>
                  {this.state.selectedConnection.status === "invited" && this.state.selectedConnection.userId === this.props.userState.user.id && (
                    <p>If you know and trust {this.state.selectedConnection.providerFirstName} {this.state.selectedConnection.providerLastName}, you can accept their invitation to connect. This will allow you to manage messages and send tools or information to that provider.</p>
                  )}
                  {this.state.selectedConnection.status === "invited" && this.state.selectedConnection.userId !== this.props.userState.user.id && (
                    <p>That user has not accepted your invitation yet.</p>
                  )}
                  {this.state.selectedConnection.status === "declined" && this.state.selectedConnection.userId === this.props.userState.user.id && (
                    <p>If you know and trust {this.state.selectedConnection.providerFirstName} {this.state.selectedConnection.providerLastName}, you can accept their invitation to connect. This will allow you to manage messages and send tools or information to that provider.</p>
                  )}

                </Modal.Body>
                <Modal.Footer>
                  {this.getModalButtons()}
                </Modal.Footer>
              </Modal>
            </Card>
          </div>
        </div>
      </Screen>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private toggleModal(){
    this.setState({ showManageModal: !this.state.showManageModal});
  }

  private getModalButtons(){
    const buttons: JSX.Element[] = [];
    if(this.state.selectedConnection.status === "invited" && this.state.selectedConnection.userId === this.props.userState.user.id){
      buttons.push(<button key="accept" className="btn btn-block btn-primary" onClick={this.acceptConnection}>Accept Connection</button>)
      buttons.push(<button key="decline" className="btn btn-block btn-danger" onClick={this.declineConnection}>Deny Connection</button>)
    }
    if(this.state.selectedConnection.status === "accepted"){
      buttons.push(<button key="decline" className="btn btn-block btn-danger" onClick={this.declineConnection}>Remove Connection</button>)
    }
    if(this.state.selectedConnection.status === "declined" && this.state.selectedConnection.userId === this.props.userState.user.id){
      buttons.push(<button key="accept" className="btn btn-block btn-primary" onClick={this.acceptConnection}>Accept Connection</button>)
    }

    buttons.push(<button key="done" className="btn btn-block btn-secondary" onClick={this.toggleModal}>Done</button>);
    return buttons;
  }

  private async setup(){
    // we want to first see if they have any connection requests; if so, we want to prompt that front and center
    try{
      const result = await UserAPI.getUserConnections();
      const connections: IUserToUserConnection[] = [];
      for(const c of result.body.data){
          c.created = moment(c.created);
          c.updated = moment(c.updated);
          connections.push(c);
      }
      this.setState({loading: false, connections})
    }catch(err){
      this.setState({ loading: false });
    }
  }

  private acceptConnection(){
    this.setState({ loading: true }, async () => {
      try{
        const data = {
          status: "accepted",
          providerId: this.state.selectedConnection.providerId,
          userId: this.state.selectedConnection.userId,
        };
        await UserAPI.updateUserConnections(data);
        const connections: IUserToUserConnection[] = [];
        for(const c of this.state.connections){
          if(c.id === this.state.selectedConnection.id){
            c.status = "accepted";
          }
          connections.push(c);
        }
        success("Accepted!");
        this.setState({ loading: false, selectedConnection: BlankUserToUserConnection, showManageModal: false, connections });
      }catch(err){
        error("Could not accept that connection. Try again or contact support.");
        this.setState({ loading: false });
      }
    });
  }

  private declineConnection(){
    this.setState({ loading: true }, async () => {
      try{
        const data = {
          status: "declined",
          providerId: this.state.selectedConnection.providerId,
          userId: this.state.selectedConnection.userId,
        };
        await UserAPI.updateUserConnections(data);
        const connections: IUserToUserConnection[] = [];
        for(const c of this.state.connections){
          if(c.id === this.state.selectedConnection.id){
            c.status = "declined";
          }
          connections.push(c);
        }
        success("Declined!");
        this.setState({ loading: false, selectedConnection: BlankUserToUserConnection, showManageModal: false, connections });
      }catch(err){
        error("Could not decline that connection. Try again or contact support.");
        this.setState({ loading: false });
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Connections) as any);
import moment from "moment";
import * as React from "react";
import { JournalAPI } from "src/api";

interface IJournalListItemProps {
  user: any;
  entry: any;
  onSelectForViewing: any;
  onSelectForEditing: any;
  onSelectForSharing: any;
  onSelectForDeleting: any;
}

interface IJournalListItemState {
  loading: boolean;
}

export default class JournalListItem extends React.Component<IJournalListItemProps, IJournalListItemState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false
    };

    this.selectForDeleting = this.selectForDeleting.bind(this);
    this.selectForEditing = this.selectForEditing.bind(this);
    this.selectForSharing = this.selectForSharing.bind(this);
    this.selectForViewing = this.selectForViewing.bind(this);
  }

  componentDidMount(){
  }

  public render() {
    return (
      <div className="journal-entry-list-item" style={{marginBottom: 10, borderBottom: "thin solid white"}}>
        <div className="row">
          <div className="col-6 col-lg-1 col-md-6">
            {moment(this.props.entry.date).format("MM/DD/YY")}
          </div>
          <div className="col-6 col-lg-1 col-md-6">
            {JournalAPI.translateStatus(this.props.entry.status)}
          </div>
          <div className="col-7 col-lg-5 col-md-8">
            {this.props.entry.title}
          </div>
          <div className="col-1 col-lg-1 col-md-1">
            <i className="bi-eye-fill icon actionable" title="View" onClick={this.selectForViewing} />
          </div>
          <div className="col-1 col-lg-1 col-md-1">
            <i className="bi-pencil-fill icon actionable" title="Edit" onClick={this.selectForEditing} />
          </div>
          <div className="col-1 col-lg-1 col-md-1">
            {this.props.entry.status === "published" ? 
              (<i className="bi-share-fill icon actionable" title="Share" onClick={this.selectForSharing} />) : 
              (<span>--</span>)}
          </div>
          <div className="col-1 col-lg-1 col-md-1">
            <i className="bi-trash-fill icon actionable" title="Delete" onClick={this.selectForDeleting} />
          </div>
        </div>
      </div>
    );
  }

  private selectForViewing(){
    this.props.onSelectForViewing(this.props.entry);
  }

  private selectForEditing(){
    this.props.onSelectForEditing(this.props.entry);
  }

  private selectForDeleting(){
    this.props.onSelectForDeleting(this.props.entry);
  }

  private selectForSharing(){
    this.props.onSelectForSharing(this.props.entry);
  }

}
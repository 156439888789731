import { combineReducers } from "redux";

import appReducer from "./appReducer";
import userReducer from "./userReducer";


const reducers = combineReducers({
  appState: appReducer,
  userState: userReducer,
});

export default reducers;
import * as React from "react";
import { JournalAPI } from "src/api";
import { error, success } from "src/components/structure/Alert";
import LoadingSpinner from "src/components/structure/LoadingSpinner";

interface IStarterProps {
  connection: any;
  entryId: number;
}

interface IStarterState {
  loading: boolean;
  connection: any;
}

export default class Starter extends React.Component<IStarterProps, IStarterState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      connection: props.connection,
    };

    this.shareEntry = this.shareEntry.bind(this);
    this.unshareEntry = this.unshareEntry.bind(this);
  }

  componentDidMount(){
  }

  public render() {
    return (
      <div className="journal-entry-share-row row">
        <div className="col-7">
          {this.props.connection.providerTitle} {this.props.connection.provideFirstName} {this.props.connection.providerLastName}
        </div>
        <div className="col-3">
          {this.state.connection.shared ? ("Shared") : ("Not Shared")}
        </div>
        <div className="col-2">
          {this.state.loading ? (<LoadingSpinner size="small" />) : this.props.connection.shared ? 
            (<i className="bi-trash-fill icon actionable" title="Unshare" onClick={this.unshareEntry} />) : 
            (<i className="bi-share-fill icon actionable" title="Share" onClick={this.shareEntry} />)}
        </div>
      </div>
    );
  }

  private shareEntry(){
    this.setState({ loading: true }, async () => {
      try{
        await JournalAPI.shareJournalEntry(this.props.entryId, this.props.connection.providerId);
        success("Shared!");
        const connection = this.state.connection;
        connection.shared = true;
        this.setState({ connection, loading: false });
      }catch(err){
        error("Could not share that journal entry.")
      }
    })
  }

  private unshareEntry(){
    this.setState({ loading: true }, async () => {
      try{
        await JournalAPI.unshareJournalEntry(this.props.entryId, this.props.connection.providerId);
        const connection = this.state.connection;
        connection.shared = false;
        this.setState({ connection, loading: false });
        success("Unshared!");
      }catch(err){
        error("Could not unshare that journal entry.")
      }
    })

  }

}
import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Card from "src/components/structure/Card";
import LoadingButton from "src/components/structure/LoadingButton";
import { error } from "src/components/structure/Alert";

import { UserAPI } from "src/api";

import * as UserActions from "src/reducers/userReducer";
import { bindActionCreators } from "redux";
import { PasswordInput } from "src/components/structure/PasswordInput";

interface ILoginScreenProps {
  userState: any;
  userActions: any;
  history: any;
}

interface ILoginScreenState {
  email: string;
  password: string;
  loading: boolean;
  env: "local" | "demo" | "dev" | "production" | string;
}

class Login extends React.Component<ILoginScreenProps, ILoginScreenState> {

  constructor(props: any) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loading: false,
      env: "local",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateField = this.updateField.bind(this);
    this.checkEnter = this.checkEnter.bind(this);

    this.copy.bind(this);
  }

  componentDidMount(){
    // if we get here and the user is logged in AND the expired key is set, then we log them out
    if(this.props.userState.loggedIn){
      if(window.localStorage.getItem("expired")){
        this.props.userActions.logoutUser();
      }
    }
    let env = process.env.REACT_APP_ENV || "local";
    if(env === "dev" || env === "develop"){
      env = "dev";
    } else if(env === "demo" || env === "demonstration"){
      env = "demo";
    }
    this.setState({ env });
  }

  public render() {
    return (
      <div className="row justify-content-md-center">
        {this.state.env === "demo" && (
          <div className="col-lg-4 col-sm-12">
            <Card title="Voithos Demo">
              <p>Thank you for trying the Voithos demo account! This is the Client Portal. If you are looking for the Provider Portal, please go to <a href="https://demopro.voithosapp.com" target="_new">https://demopro.voithosapp.com</a>. This instance is <strong>wiped and restored daily with semi-randomized data.</strong> It is meant to give you an opportunity to explore many of the benefits Voithos can bring to your services. We have configured several different account configurations for you to try. Remember, this is a demonstration, and not all features are enabled. For example, actual emails and messages will not be sent to avoid abuse.</p>
              <p>To get started, login with one of the following accounts.</p>
              <p>
                <strong className="actionable" onClick={() => this.copy("client_1@voithosapp.com", "demo_client_123")}>Subscribed Provider's Client:</strong>
                <ul>
                  <li>Email: client_1@voithosapp.com</li>
                  <li>Password: demo_client_123</li>
                </ul>
              </p>
              <p>
                <strong className="actionable" onClick={() => this.copy("client_3@voithosapp.com", "demo_client_123")}>Free Account Provider's Client:</strong>
                <ul>
                  <li>Email: client_3@voithosapp.com</li>
                  <li>Password: demo_client_123</li>
                </ul>
              </p>
            </Card>
          </div>
        )}
        <div className="col-lg-6 col-sm-12">
          {!this.props.userState.loggedIn ? (
            <Card title="Login">
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>Email</label>
                    <input type="text" id="email" className="form-control" value={this.state.email} onChange={this.updateField} onKeyUp={this.checkEnter} />
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <PasswordInput id="password" value={this.state.password} onChange={this.updateField} onKeyUp={this.checkEnter} />
                  </div>
                  <div className="form-group">
                    <LoadingButton onClick={this.handleSubmit} className="btn btn-block btn-primary" text="Login" loading={this.state.loading} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6 pull-right">
                  <Link to="/signup">Signup</Link><br />
                </div>
                <div className="col-6 pull-right">
                  <Link to="/reset">Forgot?</Link><br />
                </div>
              </div>
            </Card>) : (
              <Card title="Login">
                <div className="row">
                  <div className="col-md-6">
                    You are logged in!
              </div>
                </div>
              </Card>
            )}
        </div>

      </div>
    );
  }

  public async handleSubmit() {
    if(this.state.email.trim() === "" || this.state.password.trim() === ""){
      return error("Email and password are both required.");
    }
    this.setState({loading: true}, async () => {
      
      try {
        const result = await UserAPI.loginUser(this.state.email, this.state.password);
        const user = result.body.data;
        user.jwt = "";
        user.access_token = "";
        user.refresh_token = "";
        user.loggedIn = true;

        window.localStorage.user = JSON.stringify(user);

        // set the action
        this.props.userActions.loginUser({loggedIn: true, user});
        window.localStorage.removeItem("expired")
        const red = window.localStorage.getItem("redirect");
        if(!red || red === ""){
          window.location.href = "/dashboard";
        } else {
          window.localStorage.removeItem("redirect");
          window.location.href = red;
        }

      } catch (e) {
        error("Could not log you in.");
        this.setState({loading: false});
      }
    });
  }

  private updateField(e: any) {
    const ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private checkEnter(e: any){
    if(e.keyCode === 13) {
      this.handleSubmit();
    }
  }

  private copy(email: string, password: string){
    this.setState({
      email,
      password,
    });
  }
}


const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login) as any);
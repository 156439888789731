import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// css 
import "src/css/bootstrap.min.darkly.css";
import "../node_modules/bootstrap/dist/css/bootstrap-grid.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.js";
import "react-day-picker/lib/style.css";
import "react-toastify/dist/ReactToastify.css";
import "../node_modules/bootstrap-icons/font/bootstrap-icons.css";
import "src/css/voithos.css";

ReactDOM.render(
    <App />,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
if ((module as any).hot) {
  (module as any).hot.accept();
}

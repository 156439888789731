import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Card from "src/components/structure/Card";
import Screen from "src/components/structure/Screen";
import * as AppActions from "src/reducers/appReducer";
import moment from "moment";
import { RememberJarAPI, UserAPI } from "src/api";
import { Modal } from "react-bootstrap";
import DatePicker from "src/components/structure/DatePicker";
import RememberJarListItem from "./RememberJarListItem";
import { error, success } from "src/components/structure/Alert";

interface IRememberJarScreenProps {
  appActions: any;
}

interface IRememberJarScreenState {
  loading: boolean;

  fetchStart: moment.Moment;
  fetchEnd: moment.Moment;

  allEntries: any[];
  filteredEntries: any[];
  filterVisibility: "all" | "private" | "shared";
  filterType: "all" | "good_event" | "purpose";

  newDateOccurred: moment.Moment;
  newType: "good_event" | "purpose";
  newSummary: string;
  newVisibility: "private" | "shared";

  selectedEntry: any;
  showCreateModal: boolean;
  showEditModal: boolean;
  showDeleteModal: boolean;

  rememberGoodReminder: "none" | "daily" | "weekly";
  rememberPurposeReminder: "none" | "daily" | "weekly";

}


class RememberJarScreen extends React.Component<IRememberJarScreenProps, IRememberJarScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: true,

      fetchStart: moment("2001-01-01"),
      fetchEnd: moment("2050-01-01"),

      allEntries: [],
      filteredEntries: [],
      filterType: "all",
      filterVisibility: "all",

      newDateOccurred: moment(),
      newType: "good_event",
      newVisibility: "private",
      newSummary: "",

      selectedEntry: null,
      showCreateModal: false,
      showDeleteModal: false,
      showEditModal: false,

      rememberGoodReminder: "none",
      rememberPurposeReminder: "none",
    };

    this.updateField = this.updateField.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.updateSelectedEntry = this.updateSelectedEntry.bind(this);
    this.updateNewDateOccurred = this.updateNewDateOccurred.bind(this);
    this.setup = this.setup.bind(this);

    this.filter = this.filter.bind(this);
    this.createEntry = this.createEntry.bind(this);
    this.updateEntry = this.updateEntry.bind(this);
    this.deleteEntry = this.deleteEntry.bind(this);
    this.toggleShowCreateModal = this.toggleShowCreateModal.bind(this);
    this.toggleShowDeleteModal = this.toggleShowDeleteModal.bind(this);
    this.toggleShowEditModal = this.toggleShowEditModal.bind(this);
    this.selectEntry = this.selectEntry.bind(this);

    this.savePreferences = this.savePreferences.bind(this);
  }

  componentDidMount(){
    this.setup();
  }

  public render() {
    return (
      <Screen fileName="RememberJarScreen.tsx">
        <div className="row">
            <div className="col-lg-3">
                <div className="row">
                    <div className="col-12">
                        <Card title="About" loading={this.state.loading} help="">
                            Life can be challenging. It's useful, both before tough times and during, to remember the good things that happen in life
                            or remember the "why" we do what we do. Your purpose is what drives you forward. Feel free to record the good things and optionally have
                            periodic reminders of them sent to you!
                        </Card>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Card title="Reminders" loading={this.state.loading} help="">
                            We can send you a regular reminder about these Remember Jar entries. Just let us know how frequently you would like to
                            be reminded of these. Depending on how many you have, the selected entries may be random!

                            <div className="form-group">
                                <label>Send me a "Good Event Reminder"</label>
                                <select className="form-control" value={this.state.rememberGoodReminder} id="rememberGoodReminder" onChange={this.updateField}>
                                    <option value="none">Never</option>
                                    <option value="daily">Daily</option>
                                    <option value="weekly">Weekly</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Send me a "Purpose"</label>
                                <select className="form-control" value={this.state.rememberPurposeReminder} id="rememberPurposeReminder" onChange={this.updateField}>
                                    <option value="none">Never</option>
                                    <option value="daily">Daily</option>
                                    <option value="weekly">Weekly</option>
                                </select>
                            </div>

                            <div className="form-group">
                                <button className="btn btn-block btn-primary" onClick={this.savePreferences}>Save Preferences</button>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
            <div className="col-lg-9">
                <Card title="Remember Jar" loading={this.state.loading} help="">
                    <div className="row" style={{marginBottom: 25, paddingBottom: 10, borderBottom: "thin white solid"}}>
                        <div className="col-lg-3">
                            <label>Show With Visibility</label>
                            <select className="form-control" value={this.state.filterVisibility} id="filterVisibility" onChange={this.updateFilter}>
                                <option value="all">All</option>
                                <option value="private">Private</option>
                                <option value="shared">Shared</option>
                            </select>
                        </div>
                        <div className="col-lg-3">
                            <label>Show By Type</label>
                            <select className="form-control" value={this.state.filterType} id="filterType" onChange={this.updateFilter}>
                                <option value="all">All</option>
                                <option value="good_event">Good Events</option>
                                <option value="purpose">Purpose and "Why"</option>
                            </select>
                        </div>
                        <div className="col-lg-3">
                            <button className="btn btn-block btn-success" onClick={this.toggleShowCreateModal} style={{marginTop: 31}}>New Memory</button>
                        </div>
                    </div>

                    <div className="row">
                    <div className="col-12">
                        {this.state.filteredEntries.length === 0 && (
                        <strong>No entries match that criteria</strong>
                        )}
                        {this.state.filteredEntries.map((entry: any) => {
                            return <RememberJarListItem
                                key={entry.id}
                                entry={entry}
                                onSelectForDeleting={() => this.selectEntry(entry, "delete")}
                                onSelectForEditing={() => this.selectEntry(entry, "edit")} />
                        })}
                    </div>
                    </div>
                </Card>
            </div>
        </div>

        <Modal show={this.state.showCreateModal} onHide={this.toggleShowCreateModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Create New Memory</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Date Occurred</label>
              <DatePicker date={this.state.newDateOccurred} onDateSaved={this.updateNewDateOccurred} />
            </div>
            <div className="form-group">
              <label>Memory Type</label>
              <select className="form-control" id="newType" value={this.state.newType} onChange={this.updateField}>
                <option value="good_event">A Good or Positive Event or Interaction</option>
                <option value="purpose">A Reminder of My Purpose or "Why"</option>
              </select>
            </div>
            <div className="form-group">
              <label>Summary</label>
              <textarea className="form-control" id="newSummary" value={this.state.newSummary} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Share with Providers?</label>
              <select className="form-control" id="newVisibility" value={this.state.newVisibility} onChange={this.updateField}>
                <option value="private">No</option>
                <option value="shared">Yes</option>
              </select>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-block btn-primary" onClick={this.createEntry}>Create</button>
            <button className="btn btn-block btn-info" onClick={this.toggleShowCreateModal}>Cancel</button>
          </Modal.Footer>
        </Modal>

        {this.state.selectedEntry && (
           <div>
                <Modal show={this.state.showEditModal} onHide={this.toggleShowEditModal}>
                    <Modal.Header closeButton={true}>
                        <Modal.Title>Edit Memory</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <label>Date Occurred</label>
                            <DatePicker date={this.state.selectedEntry.dateOccurred} onDateSaved={this.updateNewDateOccurred} />
                        </div>
                        <div className="form-group">
                            <label>Memory Type</label>
                            <select className="form-control" id="newType" value={this.state.selectedEntry.rememberType} onChange={this.updateField}>
                                <option value="good_event">A Good or Positive Event or Interaction</option>
                                <option value="purpose">A Reminder of My Purpose or "Why"</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Summary</label>
                            <textarea className="form-control" id="newSummary" value={this.state.selectedEntry.summary} onChange={this.updateField} />
                        </div>
                        <div className="form-group">
                            <label>Share with Providers?</label>
                            <select className="form-control" id="newVisibility" value={this.state.selectedEntry.visibility} onChange={this.updateField}>
                                <option value="private">No</option>
                                <option value="shared">Yes</option>
                            </select>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-block btn-primary" onClick={this.updateEntry}>Update</button>
                        <button className="btn btn-block btn-info" onClick={this.toggleShowEditModal}>Cancel</button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showDeleteModal} onHide={this.toggleShowDeleteModal}>
                    <Modal.Header closeButton={true}>
                        <Modal.Title>Delete Memory</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <p><strong className="text-danger">Warning: </strong> You are about to delete this entry. Are you absolutely sure? This cannot be undone!</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-block btn-danger" onClick={this.deleteEntry}>Yes, Delete</button>
                        <button className="btn btn-block btn-info" onClick={this.toggleShowDeleteModal}>Cancel</button>
                    </Modal.Footer>
                </Modal>
            </div>
        )}       
        
      </Screen>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private updateFilter(e: any) {
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns, this.filter);
  }

  private updateNewDateOccurred(newDate: moment.Moment){
    this.setState({ newDateOccurred: newDate });
  }

  private updateSelectedEntry(e: any){
    const ns = this.state.selectedEntry;
    ns[e.target.id] = e.target.value;
    this.setState({ selectedEntry: ns });
  }


  private setup(){
    this.setState({loading: true}, async () => {
      try{
        const profileResult = await UserAPI.getUserProfile();
        const profile = profileResult.body.data;

        const entriesResult = await RememberJarAPI.getEntries(
            this.state.fetchStart.format("YYYY-MM-DD") + "T00:00:00Z",
            this.state.fetchEnd.format("YYYY-MM-DD") + "T00:00:00Z");
        const entries = entriesResult.body.data;
        this.setState({allEntries: entries, rememberGoodReminder: profile.rememberGoodReminder, rememberPurposeReminder: profile.rememberPurposeReminder}, this.filter);
      }catch(error){
        this.setState({loading: false});
      }
    })
  }

  private filter(){
    this.setState({loading: true}, () => {
        const all = this.state.allEntries;
        const filteredEntries: any[] = [];
        for(const e of all){
            if(this.state.filterType === "all" || this.state.filterType === e.rememberType){
                if(this.state.filterVisibility === "all" || this.state.filterVisibility === e.visibility){
                    filteredEntries.push(e);
                }
            }
        }
        this.setState({ loading: false, filteredEntries });
    })
  }

  private selectEntry(entry: any, reason: "edit" | "delete"){
    this.setState({ selectedEntry: entry, showDeleteModal: reason === "delete", showEditModal: reason === "edit"});
  }

  private toggleShowCreateModal(){
    this.setState({ showCreateModal: !this.state.showCreateModal});
  }

  private toggleShowEditModal(){
    this.setState({ showEditModal: !this.state.showEditModal});
  }

  private toggleShowDeleteModal(){
    this.setState({ showDeleteModal: !this.state.showDeleteModal});
  }

  private createEntry(){
    const data = { 
        dateOccurred: this.state.newDateOccurred.format("YYYY-MM-DD"),
        summary: this.state.newSummary.trim(),
        rememberType: this.state.newType,
        visibility: this.state.newVisibility,
    };
    if(data.summary === ""){
        return error("Summary cannot be blank");
    }
    this.setState({ loading: true }, async () => {
        try{
            const result = await RememberJarAPI.createEntry(data);
            const newEntry = result.body.data;
            const allEntries = this.state.allEntries;
            allEntries.push(newEntry);
            success("Memory Created!");
            this.setState({ allEntries, newDateOccurred: moment(), newSummary: "", showCreateModal: false}, this.filter);
        }catch(err){
            error("Could not save that entry");
            this.setState({ loading: false });
        }
    })
  }

  private updateEntry(){
    const data = { ...this.state.selectedEntry};
    if(data.summary === ""){
        return error("Summary cannot be blank");
    }
    this.setState({ loading: true }, async () => {
        try{
            await RememberJarAPI.updateEntry(this.state.selectedEntry.id, data);
            const allEntries: any[] = [];
            for(const e of this.state.allEntries){
                if(e.id === this.state.selectedEntry.id){
                    allEntries.push(data);
                } else {
                    allEntries.push(e);
                }
            }
            success("Memory Updated!");
            this.setState({ allEntries, newDateOccurred: moment(), newSummary: "", showEditModal: false}, this.filter);
        }catch(err){
            error("Could not save that entry");
            this.setState({ loading: false });
        }
    })
  }

  private deleteEntry(){
    this.setState({ loading: true }, async () => {
        try{
            await RememberJarAPI.deleteEntry(this.state.selectedEntry.id);
            const allEntries: any[] = [];
            for(const e of this.state.allEntries){
                if(e.id !== this.state.selectedEntry.id){
                    allEntries.push(e);
                }
            }
            success("Deleted!");
            this.setState({ allEntries, selectedEntry: null, showDeleteModal: false }, this.filter)
        }catch(err){
            error("Could not delete that entry");
            this.setState({ loading: false });
        }
    })
  }

  private savePreferences(){
    this.setState({ loading: true }, async () => {
        try{
            await UserAPI.updateUserProfile({rememberGoodReminder: this.state.rememberGoodReminder, rememberPurposeReminder: this.state.rememberPurposeReminder});
            success("Updated Preferences!");
            this.setState({ loading: false })
        }catch(err){
            error("Could not save your preferences");
            this.setState({ loading: false });
        }
    })
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RememberJarScreen) as any);
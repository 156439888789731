import { toast } from "react-toastify";

export const error = (message: string) => {
  toast(message, {
    position: "top-center",
    type: "error",
    theme: "dark"
  });
}

export const success = (message: string) => {
  toast(message, {
    position: "top-center",
    type: "success",
    theme: "dark"
  });
}
import * as React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
import moment from "moment";

import Card from "src/components/structure/Card";
import Screen from "src/components/structure/Screen";
import * as AppActions from "src/reducers/appReducer";
import { ToolsAPI } from "src/api";
import { BlankTool, ITool, IToolRequest, IToolSubmission } from "src/api/tools";

interface IToolsDashboardProps {
  appActions: any;
}

interface IToolsDashboardState {
  loading: boolean;
  tools: ITool[];
  showNewSubmissionModal: boolean;
  availableCategories: string[];
  selectedCategory: string;
  selectedTool: ITool;

  submissions: IToolSubmission[];
  requests: IToolRequest[];
}

class ToolsDashboard extends React.Component<IToolsDashboardProps, IToolsDashboardState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      tools: [],
      showNewSubmissionModal: false,
      availableCategories: [],
      selectedCategory: "",
      selectedTool: BlankTool,

      submissions: [],
      requests: [],
    };

    this.updateField = this.updateField.bind(this);
    this.toggleNewSubmissionModal = this.toggleNewSubmissionModal.bind(this);
    this.setup = this.setup.bind(this);
    this.startNewTool = this.startNewTool.bind(this);
  }

  componentDidMount(){
    this.setup();
  }

  public render() {
    return (
      <Screen fileName="ToolsDashboard.tsx">
        <div className="row">
          <div className="col-lg-6">
            <Card title="Requested Submissions" loading={this.state.loading} help="Below you will see submission requests from your providers.">
              {this.state.requests.length === 0 && (
                <div className="row">
                  <div className="col-12">
                    <p>No one has requested a submission from you.</p>
                  </div>
                </div>
              )}
              {this.state.requests.length !== 0 && (
                <div className="row row-header">
                  <div className="col-lg-4">
                    Requested From
                  </div>
                  <div className="col-lg-3">
                    Tool
                  </div>
                  <div className="col-lg-3">
                    Due
                  </div>
                  <div className="col-lg-2">
                    
                  </div>
                </div>
              )}
              {this.state.requests.map((r) => {
                return (
                  <div className="row" key={r.id}>
                    <div className="col-lg-3">
                      {r.providerFirstName} {r.providerLastName}
                    </div>
                    <div className="col-lg-4">
                      {ToolsAPI.translateToolTypeToDisplay(r.toolType, this.state.tools)}
                    </div>
                    <div className="col-lg-2">
                      {r.status === "active" && (
                        <span>{moment(r.dueOn).format("M/DD/YY")}</span>
                      )}
                      {r.status !== "active" && (<span>Complete</span>)}
                    </div>
                    <div className="col-lg-2">
                      {r.status === "active" && (<Link to={`/tools/${r.toolType}/new?requestId=${r.id}&providerId=${r.providerId}`}>Begin</Link>)}
                    </div>
                  </div>
                );
              })}
            </Card>
          </div>
          <div className="col-lg-6">
            <Card title="Submitted Tools" loading={this.state.loading} help="Below you will see all of your previous submissions.">
              {this.state.submissions.length === 0 && (
                  <div className="row">
                    <div className="col-12">No submissions match that criteria.</div>
                  </div>
              )}
              {this.state.submissions.length !== 0 && (
                  <div className="row row-header">
                    <div className="col-lg-2">Submitted</div>
                    <div className="col-lg-3">Tool</div>
                    <div className="col-lg-7">Headline</div>
                  </div>
              )}
              {this.state.submissions.map((sub) => {
                return (
                  <div className="row" key={sub.id}>
                    <div className="col-lg-2">
                      <Link to={`/tools/${sub.toolType}/${sub.id}`}>{moment(sub.submittedOn).format("MM/DD/YYYY")}</Link>
                    </div>
                    <div className="col-lg-3">
                      <Link to={`/tools/${sub.toolType}/${sub.id}`}>{ToolsAPI.translateToolTypeToDisplay(sub.toolType, this.state.tools)}</Link>
                    </div>
                    <div className="col-lg-7">
                      <Link to={`/tools/${sub.toolType}/${sub.id}`}>{sub.headline}</Link>
                    </div>
                  </div>
                )
              })}
              
              <button className="btn btn-block btn-primary" onClick={this.toggleNewSubmissionModal}>Start New Submission</button>

              <Modal show={this.state.showNewSubmissionModal} onHide={this.toggleNewSubmissionModal} dialogClassName="modal-large" >
                <Modal.Header closeButton={true}>
                  <Modal.Title>Start a New Submission</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="form-group">
                    <label htmlFor="selectedCategory">Category:</label>
                    <select className="form-control" id="selectedCategory" value={this.state.selectedCategory} onChange={this.updateField}>
                      <option key="0" value="" disabled={true}>Select a Category</option>
                      {this.state.availableCategories.map((cat) => {
                        return <option key={cat}>{cat}</option>
                      })}
                    </select>
                  </div>
                  {this.state.selectedCategory !== "" && (
                    <div className="form-group">
                      <label htmlFor="selectedTool">Tool:</label>
                      <select className="form-control" id="selectedTool" value={this.state.selectedTool.toolType} onChange={this.updateField}>
                        <option key="0" value="" disabled={true} selected={true}>Select a Tool</option>
                        {this.state.tools.map((tool) => {
                          if(tool.active && tool.category === this.state.selectedCategory){
                            return <option key={tool.toolType} value={tool.toolType}>{tool.display}</option>
                          }
                          return null;
                        })}
                      </select>
                    </div>
                  )}
                  {this.state.selectedTool.description !== "" && (
                    <div>
                      <div className="form-group">
                        <strong>Description</strong>
                        <p>{this.state.selectedTool.description}</p>
                      </div>
                      <div className="form-group">
                        <strong>Warning:</strong> Do not begin any treatment or use any tool unless you have been instructed to do so by a trained provider or if you are experienced with the tool. Voithos does not give medical advice.
                      </div>
                    </div>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  {this.state.selectedTool.description !== "" && (
                    <button className="btn btn-block btn-primary" onClick={this.startNewTool}>Start</button>
                  )}
                  <button className="btn btn-block btn-secondary" onClick={this.toggleNewSubmissionModal}>Nevermind</button>
                </Modal.Footer>
              </Modal>
            </Card>
          </div>
        </div>
      </Screen>
    );
  }
  
  private updateField(e: any){
    const id = e.target.id;
    const val = e.target.value;
    const ns: any = this.state;
    ns[id] = val;

    // if the category changed, reset the selectedTool
    if(id === "selectedCategory"){
      ns.selectedTool = BlankTool;
    }

    if(id === "selectedTool"){
      // update the tool as well
      let tool: any = {};
      for(const t of this.state.tools){
        if(t.toolType === val){
          tool = t;
          break;
        }
      }
      ns.selectedTool = tool;
      this.setState(ns);
    } else {
      this.setState(ns);
    }
  }

  private toggleNewSubmissionModal(){
    this.setState({ showNewSubmissionModal: !this.state.showNewSubmissionModal});
  }

  private setup(){
    this.setState({loading: true}, async () => {
      try{
        const toolsResult = await ToolsAPI.getTools();
        const availableCategories: string[] = [];
        for(const c of toolsResult.body.data){
          if(availableCategories.indexOf(c.category) === -1){
            availableCategories.push(c.category);
          }
        }
        const submissionsResult = await ToolsAPI.getSubmissions();

        const requestsResult = await ToolsAPI.getRequests();
        const requests: IToolRequest[] = [];
        for(const r of requestsResult.body.data.asUser){
          r.dueOn = moment(r.dueOn);
          r.requestedOn = moment(r.requestedOn);
          requests.push(r);
        }
        this.setState({
          loading: false, 
          availableCategories, 
          tools: toolsResult.body.data, 
          submissions: submissionsResult.body.data,
          requests
        });
      }catch(error){
        this.setState({loading: false});
      }
    })
  }

  private startNewTool(){
    const to = `/tools/${this.state.selectedTool.toolType}/new`;
    window.location.href = to;
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ToolsDashboard) as any);
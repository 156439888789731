import * as React from "react";

interface ILosingSpinnerProps {
  size?: "small";
}

interface ILosingSpinnerState {
  height: string;
  width: string;
}

export default class LosingSpinner extends React.Component<ILosingSpinnerProps, ILosingSpinnerState> {

  constructor(props: any){
    super(props);
    this.state = {
      height: props.size && props.size === "small" ? "1rem" : "2rem",
      width: props.size && props.size === "small" ? "1rem" : "2rem"
    };

  }

  public render() {
    return (
      <div style={{textAlign: "center"}}>
        <div className="spinner-border" role="status" style={{
          width: this.state.width,
          height: this.state.height,
        }}>
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

}
import moment from "moment";
import * as React from "react";
import { IMessage } from "src/api/messages";

// we need to come back and add in the functionality for archiving

interface IMessageListItemProps {
  user: any;
  message: IMessage;
  onMessageSelected: any;
  onMessageArchived: any;
  onMessageReplied: any;
}

interface IMessageListItemState {
  loading: boolean;
}

export default class MessageListItem extends React.Component<IMessageListItemProps, IMessageListItemState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false
    };

    this.updateField = this.updateField.bind(this);
    this.setup = this.setup.bind(this);
    this.selectMessage = this.selectMessage.bind(this);
    this.archiveMessage = this.archiveMessage.bind(this);
    this.replyToMessage = this.replyToMessage.bind(this);
  }

  componentDidMount(){
    this.setup();
  }

  public render() {
    return (
      <div key={this.props.message.id} style={{borderBottom: "white solid thin", marginBottom: 10}}>
        <div className="row">
          <div className="col-lg-1 col-md-1 col-xs-1">
            {this.props.message.status === "sent" && this.props.message.toId === this.props.user.id && (
              <i className="bi-asterisk" title="New!" />
            )}
          </div>
          <div className="col-lg-2 col-md-11 col-xs-11">
            <strong>{
              this.props.message.status === "pending" ?
              moment(this.props.message.sendDate).format("MM/DD/YYYYY") + " [pending]" :
              moment(this.props.message.deliveredOn).utc().format("MM/DD/YYYY h:mm A")
            }</strong>
          </div>
          <div className="col-lg-3 col-md-12 col-xs-12">
            {this.props.user.id === this.props.message.toId ?
            (<div>
                <i className="bi-arrow-left" /> From: {this.props.message.fromTitle} {this.props.message.fromFirstName} {this.props.message.fromLastName}
              </div>) :
            (<div>
                <i className="bi-arrow-right" /> To: {this.props.message.toTitle} {this.props.message.toFirstName} {this.props.message.toLastName}
              </div>)}
            
          </div>
          <div className="col-lg-4 col-md-6 col-xs-6 actionable" onClick={this.selectMessage}>
            {this.props.message.subject}
          </div>
          <div className="col-lg-1 col-md-1 col-xs-1">
            <i className="bi-eye-fill icon actionable" title="View" onClick={this.selectMessage} />
          </div>
          <div className="col-lg-1 col-md-1 col-xs-1">
            {this.props.message.fromId !== this.props.user.id && (
            <i className="bi-reply-fill icon actionable" title="Reply" onClick={this.replyToMessage} />
            )}
          </div>
        </div>
      </div>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }


  private setup(){
  }

  private selectMessage(){
    this.props.onMessageSelected(this.props.message);
  }

  private replyToMessage(){
    this.props.onMessageReplied(this.props.message);
  }

  private archiveMessage(){
    this.props.onMessageArchived(this.props.message);
  }

}
import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
// import * as queryString from "query-string";

import Card from "src/components/structure/Card";
import Screen from "src/components/structure/Screen";
import * as AppActions from "src/reducers/appReducer";

import { error, success } from "src/components/structure/Alert";
import { ToolsAPI, UserAPI } from "src/api";
import NewToolShareModal from "./NewToolShareModal";
import ToolDisplay from "./ToolDisplay";

interface INewToolScreenProps {
  userState: any;
  location: any;
  match: any;
}

interface INewToolScreenState {
  loading: boolean;
  toolType: string;
  showCloseModal: boolean;
  showSubmitModal: boolean;
  toolData: any;
  connections: any[];

  showShareOfferModal: boolean;
  showShareModal: boolean;
  submissionId: number;
}

class NewToolScreen extends React.Component<INewToolScreenProps, INewToolScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      toolType: this.props.match.params.toolType,
      showCloseModal: false,
      showSubmitModal: false,
      toolData: {},
      connections: [],

      showShareOfferModal: false,
      showShareModal: false,
      submissionId: 0
    };

    this.updateField = this.updateField.bind(this);
    this.showTool = this.showTool.bind(this);
    this.toggleShowCloseModal = this.toggleShowCloseModal.bind(this);
    this.toggleShowSubmitModal = this.toggleShowSubmitModal.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setup = this.setup.bind(this);
    this.submit = this.submit.bind(this);
    this.close = this.close.bind(this);
    this.toggleShareOfferModal = this.toggleShareOfferModal.bind(this);
    this.toggleShareModal = this.toggleShareModal.bind(this);
  }

  componentDidMount(){
    // const parsed = queryString.parse(this.props.location.search);
    this.setup();
  }

  public render() {
    return (
      <Screen fileName="NewToolScreen.tsx">
        <Card title={`New Submission`} loading={this.state.loading} help="">
          {this.showTool()}
        </Card>

        <Modal show={this.state.showCloseModal} onHide={this.toggleShowCloseModal} >
          <Modal.Header closeButton={true}>
            <Modal.Title>Close Without Saving</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to discard this submission? It will not be saved and you will have to start over.
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-block btn-danger" onClick={this.close}>Yes, Discard My Submission</button>
            <button className="btn btn-block btn-seconday" onClick={this.toggleShowCloseModal}>Nevermind, Keep Working</button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showSubmitModal} onHide={this.toggleShowSubmitModal} >
          <Modal.Header closeButton={true}>
            <Modal.Title>Submit Entry</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to save this submission? Once saved, you will have the option of sharing it with a provider.
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-block btn-success" onClick={this.submit}>Yes, Save My Submission</button>
            <button className="btn btn-block btn-seconday" onClick={this.toggleShowSubmitModal}>Nevermind, Keep Working</button>
          </Modal.Footer>
        </Modal>



        <Modal show={this.state.showShareOfferModal} onHide={this.toggleShowSubmitModal} >
          <Modal.Header closeButton={true}>
            <Modal.Title>Saved!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Your entry has been saved! Would you like to share your submission with a provider?
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-block btn-success" onClick={this.toggleShareModal}>Yes, Share My Submission</button>
            <button className="btn btn-block btn-seconday" onClick={() => window.location.href="/tools"}>Nevermind, I'm done!</button>
          </Modal.Footer>
        </Modal>

        <NewToolShareModal 
          submissionId={this.state.submissionId} 
          show={this.state.showShareModal} 
          connections={this.state.connections}
          userId={this.props.userState.user.id}
          toolType={this.state.toolType}
          onClose={() => {
            this.setState({showShareModal: false}, () => {
              window.location.href = "/tools";
            })
          }} />
      </Screen>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private showTool(){
    switch(this.state.toolType){
      case "fiveColumnThoughtRecord":
        return <ToolDisplay toolType={this.state.toolType} userId={this.props.userState.user.id} onClose={this.handleClose} onSubmit={this.handleSubmit} mode="create" />
      case "spiritualFitnessInventory":
        return <ToolDisplay toolType={this.state.toolType} userId={this.props.userState.user.id} onClose={this.handleClose} onSubmit={this.handleSubmit} mode="create"  />
      case "therapyAssessment":
        return <ToolDisplay toolType={this.state.toolType} userId={this.props.userState.user.id} onClose={this.handleClose} onSubmit={this.handleSubmit} mode="create"  />
      default:
        return null;
    }
  }

  private toggleShowCloseModal(){
    this.setState({showCloseModal: !this.state.showCloseModal});
  }

  private toggleShowSubmitModal(){
    this.setState({showSubmitModal: !this.state.showSubmitModal});
  }

  private toggleShareOfferModal(){
    this.setState({showShareOfferModal: !this.state.showShareOfferModal});
  }

  private toggleShareModal(){
    this.setState({showShareModal: !this.state.showShareModal, showShareOfferModal: false});
  }

  private handleClose(data: any){
    this.setState({ toolData: data }, () => this.toggleShowCloseModal());
  }

  private handleSubmit(data: any){
    this.setState({ toolData: data }, () => this.toggleShowSubmitModal());
  }

  private close(){
    window.location.href = "/tools";
  }

  private submit(){
    this.setState({loading: true}, async () => {
      try{
        const result = await ToolsAPI.submitNewSubmission(this.state.toolType, this.state.toolData);
        success("Submission saved!");
        if(this.state.connections.length === 0){
          this.setState({ loading: false, showSubmitModal: false });
          window.location.href = "/tools";
        } else {
          this.setState({ loading: false, showSubmitModal: false, showShareOfferModal: true, submissionId: result.body.data.id });
        }
      }catch(err){
        error("Could not save that submission. Please check your entries, ensure all required fields are entered, and try again. If you still encounter an error, please contact support.");
        this.setState({ loading: false, showSubmitModal: false });
      }
    })
  }

  private setup(){
    this.setState({loading: false}, async () => {
      try{
        const connectionsResult = await UserAPI.getUserConnections();
        const connections: any = [];
        for(const c of connectionsResult.body.data){
          if(c.status === "accepted"){
            connections.push(c);
          }
        }
        this.setState({loading: false, connections});
      }catch(error){
        this.setState({loading: false});
      }
    })
  }

}


const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewToolScreen) as any);
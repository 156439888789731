import moment from "moment";
import { makeCall } from "./client";

export class UserAPI {
  /**
   * Attempts to login a user
   * @param username 
   * @param password 
   */
  public loginUser(email: string, password: string, secretKey?: string): Promise<any> {
    return makeCall("post", "users/login", {email, password, secretKey});
  }

  /**
   * Refresh an access token, receiving it as an httpOnly cookie
   * @returns 
   */
  public refreshToken(): Promise<any> {
    return makeCall("post", "users/refresh", {});
  }

  /**
   * Logout a user and wipe the httpOnly cookies
   * @returns 
   */
  public logoutUser(): Promise<any> {
    return makeCall("post", "users/logout", {});
  }

  /**
   * Signup a new user
   * @param branch 
   * @param firstName 
   * @param lastName 
   * @param email 
   * @param password 
   * @param otherData 
   */
  public signup(branch: string, firstName: string, lastName: string, email: string, password: string, otherData: any = {}): Promise<any> {
    const data = {
      ...otherData,
      branch,
      firstName,
      lastName,
      email,
      password,
      defaultAccountType: "client"
    }
    return makeCall("post", "users/signup", data);
  }

  /**
   * Verifies a signup
   * @param email 
   * @param token 
   */
  public signupVerify(email: string, token: string): Promise<any> {
    const data = {
      email,
      token,
    };
    return makeCall("post", `users/signup/verify`, data);
  }

  /**
   * Resets a password
   * @param email 
   */
  public resetPassword(email: string): Promise<any> {
    const data = {
      email,
    };
    return makeCall("post", `users/login/reset`, data);
  }

  /**
   * Verifies the email and code for a password reset
   * @param email 
   * @param token 
   * @param newPassword 
   */
  public resetPasswordVerify(email: string, token: string, newPassword: string): Promise<any> {
    const data = {
      email,
      token,
      password: newPassword
    };
    return makeCall("post", `users/login/reset/verify`, data);
  }

  /**
   * Get a user profile
   * @param schoolId 
   * @param userId 
   */
  public getUserProfile(): Promise<any> {
    return makeCall("get", `me`);
  }

  /**
   * Update the profile information for a user
   * @param data 
   */
  public updateUserProfile(data: any): Promise<any> {
    return makeCall("patch", `me`, data);
  }

  /**
   * 
   * @param data 
   * @returns 
   */
  public getUserConnections(data: any = {}): Promise<any> {
    return makeCall("get", `/users/connections`, data);
  }

  /**
   * 
   * @param data 
   * @returns 
   */
  public updateUserConnections(data: any = {}): Promise<any> {
    return makeCall("patch", `/users/connections`, data);
  }
}

export interface IProfile {
  branch: string;
  created: moment.Moment;
  currentPlanId: number;
  defaultAccountType: string;
  email: string;
  firstName: string;
  id: number;
  lastLogin: moment.Moment;
  lastName: string;
  membership: string;
  role: string;
  shortNameDisplay: string;
  smsNumber: string;
  status: string;
  timezone: string;
  title: string;
  updated: moment.Moment;
  voiceNumber: string;
}

export const BlankProfile: IProfile = {
  branch: "Other",
  created: moment(),
  currentPlanId: 1,
  defaultAccountType: "provider",
  email: "",
  firstName: "",
  id: 0,
  lastLogin: moment(),
  lastName: "",
  membership: "free",
  role: "user",
  shortNameDisplay: "",
  smsNumber: "",
  status: "verified",
  timezone: "America/New_York",
  title: "",
  updated: moment(),
  voiceNumber: "",
}

export interface IUserToUserConnection {
  id: number;
  connectionType: "client" | "supervisor";
  created: moment.Moment;
  email: string;
  providerId: number;
  providerFirstName: string;
  providerLastName: string;
  status: "invited" | "accepted" | "declined";
  updated: moment.Moment;
  userId: number;
  userFirstName: string;
  userLastName: string;
}

export const BlankUserToUserConnection: IUserToUserConnection = {
  id: 0,
  connectionType: "client",
  created: moment(),
  email: "",
  providerId: 0,
  providerFirstName: "",
  providerLastName: "",
  status: "invited",
  updated: moment(),
  userId: 0,
  userFirstName: "",
  userLastName: "",
}
import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Card from "src/components/structure/Card";
import Screen from "src/components/structure/Screen";
import * as AppActions from "src/reducers/appReducer";
import { JournalAPI, UserAPI } from "src/api";
import moment from "moment";
import DatePicker from "src/components/structure/DatePicker";
import { Modal } from "react-bootstrap";
import { error, success } from "src/components/structure/Alert";
import JournalListItem from "./JournalListItem";
import JournalShareRow from "./JournalShareRow";

// TODO: add a "themesString" field so we can keep the ids when editing

interface IJournalScreenProps {
  appActions: any;
  userState: any;
}

interface IJournalScreenState {
  loading: boolean;
  themes: any[];
  entries: any[];
  connections: any[];

  start: moment.Moment;
  end: moment.Moment;
  selectedThemeId: any;

  showNewModal: boolean;
  newDate: moment.Moment;
  newStatus: "draft" | "published",
  newTitle: string;
  newBody: string;
  newThemes: string;

  selectedEntry: any;
  selectedEntryShares: any[];

  showEditModal: boolean;
  showDeleteModal: boolean;
  showSharingModal: boolean;
  showViewingModal: boolean;
}

class JournalScreen extends React.Component<IJournalScreenProps, IJournalScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      themes: [],
      entries: [],
      connections: [],
      start: moment().subtract(6, "months"),
      end: moment().add(1, "week").hour(23).minute(59),
      selectedThemeId: 0,

      showNewModal: false,
      newDate: moment(),
      newStatus: "draft",
      newTitle: "",
      newBody: "",
      newThemes: "",

      selectedEntry: {},
      selectedEntryShares: [],

      showEditModal: false,
      showDeleteModal: false,
      showSharingModal: false,
      showViewingModal: false,
    };

    this.updateField = this.updateField.bind(this);
    this.updateEditField = this.updateEditField.bind(this);
    this.updateStart = this.updateStart.bind(this);
    this.updateEnd = this.updateEnd.bind(this);
    this.updateNewDate = this.updateNewDate.bind(this);
    this.updateEditDate = this.updateEditDate.bind(this);
    this.setup = this.setup.bind(this);
    this.fetchEntries = this.fetchEntries.bind(this);
    this.toggleNewModal = this.toggleNewModal.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.toggleEditModal = this.toggleEditModal.bind(this);
    this.toggleSharingModal = this.toggleSharingModal.bind(this);
    this.toggleViewingModal = this.toggleViewingModal.bind(this);

    this.selectEntryForDelete = this.selectEntryForDelete.bind(this);
    this.selectEntryForEdit = this.selectEntryForEdit.bind(this);
    this.selectEntryForSharing = this.selectEntryForSharing.bind(this);
    this.selectEntryForViewing = this.selectEntryForViewing.bind(this);

    this.create = this.create.bind(this);
    this.edit = this.edit.bind(this);
    this.deleteEntry = this.deleteEntry.bind(this);
    this.shareEntry = this.shareEntry.bind(this);
  }

  componentDidMount(){
    this.setup();
  }

  public render() {
    return (
      <Screen fileName="JournalScreen.tsx">
        <div className="row">
          <div className="col-12">
            <Card title="Entries" loading={this.state.loading} help="Your journal is a place to safely store thoughts, experiences, and whatever comes to mind. You can refer back to them at any time. Once published, you may optionally share your entries with a connected provider.">
              <div className="row" style={{marginBottom: 25, paddingBottom: 10, borderBottom: "thin white solid"}}>
                <div className="col-lg-3 col-md-6">
                  <label>From</label>
                  <DatePicker date={this.state.start} onDateSaved={this.updateStart} />
                </div>
                <div className="col-lg-3 col-md-6">
                  <label>To</label>
                  <DatePicker date={this.state.end} onDateSaved={this.updateEnd}/>
                </div>
                <div className="col-lg-2 col-md-6">
                  <label>With Theme</label>
                  <select className="form-control" id="selectedThemeId" value={this.state.selectedThemeId} onChange={this.updateField}>
                    <option value={0}>All</option>
                    {this.state.themes.map((th: any) => {
                      return <option key={th.id} value={th.id}>{th.display}</option>
                    })}
                  </select>
                </div>
                <div className="col-lg-2 col-md-6">
                  <button className="btn btn-block btn-primary" onClick={this.fetchEntries} style={{marginTop: 30}}>Filter</button>
                </div>
                <div className="col-lg-2 col-md-12">
                  <button className="btn btn-block btn-primary" onClick={this.toggleNewModal} style={{marginTop: 30}}>New</button>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  {this.state.entries.length === 0 && (
                    <strong>No journal entries match that criteria</strong>
                  )}
                  {this.state.entries.map((entry: any) => {
                    return <JournalListItem
                      key={entry.id}
                      user={this.props.userState}
                      entry={entry}
                      onSelectForDeleting={this.selectEntryForDelete}
                      onSelectForEditing={this.selectEntryForEdit}
                      onSelectForSharing={this.selectEntryForSharing}
                      onSelectForViewing={this.selectEntryForViewing}
                    />
                  })}
                </div>
              </div>
            </Card>
          </div>
        </div>
        
        <Modal show={this.state.showNewModal} onHide={this.toggleNewModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Create New Journal Entry</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Date</label>
              <DatePicker date={this.state.newDate} onDateSaved={this.updateNewDate} />
            </div>
            <div className="form-group">
              <label>Title</label>
              <input type="text" className="form-control" id="newTitle" value={this.state.newTitle} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Body</label>
              <textarea className="form-control" rows={4} id="newBody" value={this.state.newBody} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Themes</label>
              <input type="text" className="form-control" id="newThemes" value={this.state.newThemes} onChange={this.updateField} />
              <p className="text-small small">Separate with a comma</p>
            </div>
            <div className="form-group">
              <label>Status</label>
              <select className="form-control" id="newStatus" value={this.state.newStatus} onChange={this.updateField}>
                <option value="draft">Draft</option>
                <option value="published">Published</option>
              </select>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-block btn-primary" onClick={this.create}>Create</button>
            <button className="btn btn-block btn-info" onClick={this.toggleNewModal}>Cancel</button>
          </Modal.Footer>
        </Modal>


        {this.state.selectedEntry && this.state.selectedEntry.id && (
          <div>
            <Modal show={this.state.showViewingModal} onHide={this.toggleViewingModal}>
              <Modal.Header closeButton={true}>
                <Modal.Title>Viewing Entry</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-group">
                  {moment(this.state.selectedEntry.date).format("MM/DD/YY")}
                </div>
                <div className="form-group">
                  {this.state.selectedEntry.title}
                </div>
                <div className="form-group" style={{whiteSpace: "pre-wrap"}}>
                  {this.state.selectedEntry.body}
                </div>
                <div className="form-group">
                  Themes: {this.state.selectedEntry.themes && this.state.selectedEntry.themes.length === 0 && (<span>None</span>)}
                  {this.state.selectedEntry.themes && typeof this.state.selectedEntry.themes === "object" && this.state.selectedEntry.themes.map((th: any, i: number) => {
                    return <span key={i}>{i !== 0 && (<span>, </span>)}{th.display}</span>
                  })}
                </div>
                <div className="form-group">
                  Status: {this.state.selectedEntry.status === "draft" ? "Draft" : "Published"}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-block btn-primary" onClick={this.toggleEditModal}>Edit</button>
                <button className="btn btn-block btn-danger" onClick={this.toggleDeleteModal}>Delete</button>
                <button className="btn btn-block btn-info" onClick={this.toggleViewingModal}>Cancel</button>
              </Modal.Footer>
            </Modal>

            <Modal show={this.state.showEditModal} onHide={this.toggleEditModal}>
              <Modal.Header closeButton={true}>
                <Modal.Title>Edit Entry</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-group">
                  <label>Date</label>
                  <DatePicker date={this.state.selectedEntry.date} onDateSaved={this.updateEditDate} />
                </div>
                <div className="form-group">
                  <label>Title</label>
                  <input type="text" className="form-control" id="title" value={this.state.selectedEntry.title} onChange={this.updateEditField} />
                </div>
                <div className="form-group">
                  <label>Body</label>
                  <textarea className="form-control" rows={4} id="title" value={this.state.selectedEntry.title} onChange={this.updateEditField} />
                </div>
                <div className="form-group">
                  <label>Themes</label>
                  <input type="text" className="form-control" id="themes" value={this.state.selectedEntry.themes} onChange={this.updateEditField} />
                  <p className="text-small small">Separate with a comma</p>
                </div>
                <div className="form-group">
                  <label>Status</label>
                  <select className="form-control" id="status" value={this.state.selectedEntry.status} onChange={this.updateEditField}>
                    <option value="draft">Draft</option>
                    <option value="published">Published</option>
                  </select>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-block btn-primary" onClick={this.edit}>Save</button>
                <button className="btn btn-block btn-info" onClick={this.toggleEditModal}>Cancel</button>
              </Modal.Footer>
            </Modal>

            <Modal show={this.state.showDeleteModal} onHide={this.toggleDeleteModal}>
              <Modal.Header closeButton={true}>
                <Modal.Title>Delete Entry</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <strong className="text-danger">Warning: </strong> You are about to delete the entry titled "{this.state.selectedEntry.title}". This cannot be undone. Are you sure you want to delete this entry?
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-block btn-danger" onClick={this.deleteEntry}>Yes, Delete</button>
                <button className="btn btn-block btn-info" onClick={this.toggleDeleteModal}>Cancel</button>
              </Modal.Footer>
            </Modal>

            <Modal show={this.state.showSharingModal} onHide={this.toggleSharingModal}>
              <Modal.Header closeButton={true}>
                <Modal.Title>Share Entry</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {this.state.connections.length === 0 && (
                  <div className="row">
                    <div className="col-12">
                      <strong>You are not connected to any providers and therefore cannot share this entry.</strong>
                    </div>
                  </div>
                )}
                {this.state.connections.map((conn: any) => {
                  return <JournalShareRow key={conn.id} entryId={this.state.selectedEntry.id} connection={conn} />
                })}
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-block btn-info" onClick={this.toggleSharingModal}>Close</button>
              </Modal.Footer>
            </Modal>
          </div>
        )}
        

      </Screen>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private updateEditField(e: any){
    const selectedEntry = this.state.selectedEntry;
    selectedEntry[e.target.id] = e.target.value;
    this.setState( {selectedEntry} )
  }

  private updateStart(newDate: moment.Moment){
    this.setState({ start: newDate });
  }

  private updateEnd(newDate: moment.Moment){
    this.setState({ end: newDate });
  }

  private updateNewDate(newDate: moment.Moment){
    this.setState({ newDate });
  }

  private updateEditDate(newDate: moment.Moment){
    const selectedEntry = this.state.selectedEntry;
    selectedEntry.date = newDate;
    this.setState({ selectedEntry });
  }

  private setup(){
    this.setState({loading: true}, async () => {
      try{
        const themesResult = await JournalAPI.getJournalThemes();
        const connectionsResult = await UserAPI.getUserConnections();
        const connections: any[] = [];
        for(const c of connectionsResult.body.data){
          c.shared = false;
          connections.push(c);
        }

        this.setState({
          themes: themesResult.body.data,
          connections: connections,
        }, () => this.fetchEntries())
      }catch(error){
        this.setState({loading: false});
      }
    })
  }

  private fetchEntries(){
    this.setState({loading: true}, async () => {
      try{
        const entriesResult = await JournalAPI.getJournalEntries(
          this.state.start.format("YYYY-MM-DD") + "T00:00:00Z",
          this.state.end.format("YYYY-MM-DD") + "T23:59:59Z",
          this.state.selectedThemeId
        );
        this.setState({
          loading: false,
          entries: entriesResult.body.data,
        })
      }catch(err){
        this.setState({loading: false, entries: []});
      }
    })
  }

  private toggleNewModal(){
    this.setState({ showNewModal: !this.state.showNewModal });
  }

  private toggleViewingModal(){
    this.setState({ showViewingModal: !this.state.showViewingModal });
  }

  private toggleEditModal(){
    this.setState({ showEditModal: !this.state.showEditModal, showViewingModal: false, });
  }

  private toggleDeleteModal(){
    this.setState({ showDeleteModal: !this.state.showDeleteModal, showViewingModal: false, });
  }

  private toggleSharingModal(){
    this.setState({ showSharingModal: !this.state.showSharingModal, showViewingModal: false, });
  }

  private selectEntryForViewing(entry: any){
    this.setState({
      selectedEntry: entry,
      showViewingModal: true,
    })
  }

  private selectEntryForEdit(entry: any){
    let themes: any[] = [];
    if(entry.themes && typeof entry.themes === "object"){
      entry.themes.forEach((th: any) => {
        themes.push(th.display);
      })
      entry.themes = themes.join(", ")
    } else if(entry.themes && typeof entry.themes === "string"){
      themes = entry.themes.split(",");
    }
    this.setState({
      selectedEntry: entry,
      showEditModal: true,
    })
  }

  private selectEntryForDelete(entry: any){
    this.setState({
      selectedEntry: entry,
      showDeleteModal: true,
    })
  }

  private selectEntryForSharing(entry: any){
    // first, we need to find out who this entry has been shared with
    let selectedEntryShares: any[] = [];
    const connections: any[] = [];
    this.setState({ loading: true }, async () => {
      try{
        const res = await JournalAPI.getJournalEntryShares(entry.id);
        selectedEntryShares = res.body.data;
        this.state.connections.forEach((conn: any) => {
          selectedEntryShares.forEach((share: any) => {
            if(share.providerId === conn.providerId){
              conn.shared = true;
            }
          });
          connections.push(conn);
        })

      }catch(err){

      }finally{
        this.setState({
          loading: false,
          selectedEntry: entry,
          connections,
          showSharingModal: true,
          selectedEntryShares,
        })
      }
    })
  }

  private create(){
    const data: any = {
      title: this.state.newTitle.trim(),
      body: this.state.newBody.trim(),
      date: this.state.newDate.format("YYYY-MM-DD"),
      themes: [],
      status: this.state.newStatus,
    }
    if(data.title === "" || data.body === ""){
      return error("Title and Body are required!");
    }
    this.state.newThemes.split(",").forEach((theme: string) => {
      theme = theme.trim();
      if(theme !== ""){
        data.themes.push({id: 0, display: theme});
      }
    });

    this.setState({ loading: true }, async () => {
      try{
        const res = await JournalAPI.createJournalEntry(data);
        const entry = res.body.data;
        const entries = [entry, ...this.state.entries];
        this.setState({
          newBody: "",
          newStatus: "draft",
          newThemes: "",
          newTitle: "",
          showNewModal: false,
          loading: false,
          entries,
        })
      }catch(err){
        error("Could not create that journal entry.");
        this.setState({ loading: false });
      }      
    })
  }

  private edit(){
    const data: any = {
      ...this.state.selectedEntry,
      date: moment(this.state.selectedEntry.date).format("YYYY-MM-DD"),
      themes: [],
    }
    if(data.title === "" || data.body === ""){
      return error("Title and Body are required!");
    }
    this.state.selectedEntry.themes.split(",").forEach((theme: string) => {
      theme = theme.trim();
      if(theme !== ""){
        data.themes.push({id: 0, display: theme});
      }
    });
    this.setState({loading: true}, async () => {
      try{
        await JournalAPI.updateJournalEntry(this.state.selectedEntry.id, data);
        success("Saved!");
        this.setState({ showEditModal: false, selectedEntry: {}}, () => this.fetchEntries());
      }catch(err){
        error("Could not save that journal entry.");
        this.setState({loading: false});
      }
    })
  }

  private deleteEntry(){
    this.setState({loading: true}, async () => {
      try{
        await JournalAPI.deleteJournalEntry(this.state.selectedEntry.id);
        success("Deleted!");
        this.setState({ showDeleteModal: false, selectedEntry: {}}, () => this.fetchEntries());
      }catch(err){
        error("Could not delete that journal entry.");
        this.setState({loading: false});
      }
    })
  }

  private shareEntry(targetId: number){

  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JournalScreen) as any);
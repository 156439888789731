import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ToolsAPI } from "src/api";
import DatePicker from "src/components/structure/DatePicker";

import * as AppActions from "src/reducers/appReducer";

interface IToolDisplayProps {
  onSubmit?: any;
  onClose?: any;
  userId: number;
  mode: "create" | "view";
  toolType: string;
  submissionData?: any;
}

interface IToolDisplayState {
  loading: boolean;
  display: string;
  description: string;
  instructions: string;
  headline: string;
  notes: string;
  questionsMap: object;
  trendsAnalysis: string;
}

class IToolDisplay extends React.Component<IToolDisplayProps, IToolDisplayState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      display: "",
      headline: `Submission on ${moment().format("MM/DD/YYYY")}`,
      description: "",
      instructions: "",
      trendsAnalysis: "",
      notes: "",
      questionsMap: {},
    };

    this.updateField = this.updateField.bind(this);
    this.updateQuestionField = this.updateQuestionField.bind(this);
    this.updateQuestionFieldDate = this.updateQuestionFieldDate.bind(this);
    this.setup = this.setup.bind(this);
    this.processForSubmission = this.processForSubmission.bind(this);
  }

  componentDidMount(){
    if(this.props.submissionData && this.props.submissionData.id){
      const ns = {
        ...this.state,
        ...this.props.submissionData
      };
      this.setState(ns);
    }
    this.setup();
  }

  public render() {
    return (
      <div>
        <div className="tool-row tool-title">
          {this.state.display}
        </div>
        <div className="row tool-row tool-description">
          <div className="col-12">
            <strong>Description: </strong>
            <div className="tool-instructions">
              {this.state.description}
            </div>  
          </div>
        </div>

        <div className="row tool-row tool-item-row">
          <div className="col-12 col-lg-6">
            <label htmlFor="headline">Give this submission a name to help identify it. For example "After Meditation on June 6th" would be useful in remembering the context or identifying this entry from others.</label>
          </div>
          <div className="col-12 col-lg-6">
            <input type="text" className="form-control" id="headline" value={this.state.headline} onChange={this.updateField} disabled={this.props.mode === "view"} />
          </div>
        </div>

        {this.state.instructions !== "" && (
          <div className="row tool-row tool-instructions">
            <div className="col-12">
              <strong>Instructions: </strong>
              <div className="tool-instructions">
                {this.state.instructions}
              </div>  
            </div>
          </div>
        )}

        {Object.keys(this.state.questionsMap).map((key) => {
          return (
            <div className="row tool-row tool-item-row" key={key}>
              <div className="col-12 col-lg-6">
                <label htmlFor={key}>{this.state.questionsMap[key].display}</label>
              </div>
              <div className="col-12 col-lg-6">
                {this.generateFormFieldForQuestion(this.state.questionsMap[key])}
              </div>
            </div>
          );
        })}


        <div className="row tool-row tool-item-row">
          <div className="col-12 col-lg-6">
            <label htmlFor="notes">Notes</label>
            <p>Any additional notes you would like to attach to this submission?</p>
          </div>
          <div className="col-12 col-lg-6">
            <textarea className="form-control" id="notes" value={this.state.notes} onChange={this.updateField} rows={5}  disabled={this.props.mode === "view"} />
          </div>
        </div>

        {this.props.mode !== "view" && (
          <div className="row">
            <div className="col-lg-6 col-12">
              <button className="btn btn-block btn-secondary" onClick={() => this.props.onClose(this.state)}>Nevermind</button>
            </div>
            <div className="col-lg-6 col-12">
              <button className="btn btn-block btn-primary" onClick={this.processForSubmission}>Submit</button>
            </div>
          </div>
        )}
      </div>
    );
  }


  private setup(){
    this.setState({loading: false}, async () => {
      try{
        const results = await ToolsAPI.getToolInformation(this.props.toolType);
        let questionsMap = {};
        const questions = results.body.data.questions;
        for(const q of questions){
          q.value = "";      
          switch(q.questionType){
            case "scale5":
            case "scale5Agree":
            case "scale10":
              // number
              q.value = 1;
              break;
          }
          questionsMap[q.key] = q;
        }

        // if there is submission data, loop and set
        if(this.props.submissionData){
          for(const key of Object.keys(this.props.submissionData)){
            // we only want this if there is a question with that key, so we don't get
            // things like submittedOn or id
            if(questionsMap.hasOwnProperty(key)){
              questionsMap[key].value = this.props.submissionData[key];
            }
          }
        }
        
        this.setState({
          loading: false,
          display: results.body.data.display,
          questionsMap,
          instructions: results.body.data.instructions,
          description: results.body.data.description,
          trendsAnalysis: results.body.data.trendsAnalysis,
          headline: `${results.body.data.display} on ${moment().format("MM/DD/YY")}`,
        })
      }catch(error){
        this.setState({loading: false});
      }
    })
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private updateQuestionField(e: any){
    const key = e.target.id;
    const val = e.target.value;
    const questionsMap = this.state.questionsMap;
    questionsMap[key].value = val;
    this.setState({questionsMap});
  }

  private updateQuestionFieldDate(key: string, date: moment.Moment){
    const questionsMap = this.state.questionsMap;
    questionsMap[key].value = date;
    this.setState({questionsMap});
  }

  private generateFormFieldForQuestion(question: any){
    const disabled = this.props.mode === "view";
    switch(question.questionType){
      case "scale5":
        return (
          <select className="form-control" id={question.key} value={this.state.questionsMap[question.key].value} onChange={this.updateQuestionField} disabled={disabled}>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>);
      case "scale5Agree":
        return (
          <select className="form-control" id={question.key} value={this.state.questionsMap[question.key].value} onChange={this.updateQuestionField} disabled={disabled}>
            <option value="1">1 - Strongly Disagree</option>
            <option value="2">2 - Disagree</option>
            <option value="3">3 - Netural</option>
            <option value="4">4 - Agree</option>
            <option value="5">5 - Strongly Agree</option>
          </select>);
      case "customNumber":
        return (
        <select className="form-control" id={question.key} value={this.state.questionsMap[question.key].value} onChange={this.updateQuestionField} disabled={disabled}>
          {question.customQuestionValues.map((custom: any) => {
            return (
              <option key={custom.value} value={custom.value}>{custom.display}</option>
            );
          })}
        </select>
        );
      case "date": 
        if(disabled){
          return (< input type="text" disabled={true} className="form-control" value={moment(this.state.questionsMap[question.key].value).format("MM/DD/YYYY")} />);
        }
        return (
          <DatePicker date={moment()} onDateSaved={(newDate: moment.Moment) => {this.updateQuestionFieldDate(question.key, newDate)}} style={{width: "100%"}} />
        );
      case "textArea":
        return (<textarea className="form-control" id={question.key} value={this.state.questionsMap[question.key].value} onChange={this.updateQuestionField} disabled={disabled} rows={4} />)
      case "free":
      case "text":
      default:
        return (<input type="text" className="form-control" id={question.key} value={this.state.questionsMap[question.key].value} onChange={this.updateQuestionField} disabled={disabled} />)
    }
  }

  private processForSubmission(){
    // we want to convert the strings to numbers before sending up
    const qm = this.state.questionsMap;
    const data: any = {
      ...this.state,
    };
    for(const key of Object.keys(qm)){
      const q = this.state.questionsMap[key];
      switch(q.questionType){
        case "scale5":
        case "scale5Agree":
        case "scale10":
        case "customNumber":
          // number
          q.value = parseInt(q.value);
          break;
      }
      data[key] = q.value;
    }

    this.props.onSubmit(data);
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IToolDisplay);
import * as React from "react";

interface IButtonProps {
  loading: boolean;
  onClick: any;
  className: string;
  text: string;
}

interface IButtonState {
  loading: boolean;
}

export default class Button extends React.Component<IButtonProps, IButtonState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false
    };

  }

  public render() {
    if(this.props.loading){
      return (
        <div style={{textAlign: "center"}}>
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    }

    return (
      <button className={this.props.className === "" ? "btn btn-block btn-secondary" : this.props.className} onClick={this.props.onClick}>{this.props.text}</button>
    );
  }

}
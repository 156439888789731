import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

import Card from "src/components/structure/Card";
import Screen from "src/components/structure/Screen";
import { PasswordInput } from "src/components/structure/PasswordInput";
import * as UserActions from "src/reducers/userReducer";
import { UserAPI } from "src/api";
import { Modal } from "react-bootstrap";
import { error, success } from "src/components/structure/Alert";

interface IProfileProps {
  userActions: any;
  history: any;
}

interface IProfileState {
  loading: boolean;
  created: moment.Moment;
  lastLogin: moment.Moment;
  updated: moment.Moment;
  firstName: string;
  lastName: string;
  email: string;
  timezone: string;
  journalGoodReminder: "none" | "daily" | "weekly";
  journalPurposeReminder: "none" | "daily" | "weekly";

  showChangePasswordModal: boolean;
  password: string;
  confirmPassword: string;
}

class Profile extends React.Component<IProfileProps, IProfileState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      created: moment(),
      lastLogin: moment(),
      updated: moment(),
      firstName: "",
      lastName: "",
      email: "",
      timezone: "",
      journalGoodReminder: "none",
      journalPurposeReminder: "none",
      
      showChangePasswordModal: false,
      password: "",
      confirmPassword: "",
    };

    this.updateField = this.updateField.bind(this);
    this.toggleChangePasswordModal = this.toggleChangePasswordModal.bind(this);
    this.setup = this.setup.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }

  componentDidMount(){
    this.setup();
  }

  public render() {
    return (
      <Screen fileName="Profile.tsx">
        <div className="row">
          <div className="col-lg-6 offset-lg-3 col-12">
            <Card title="My Profile" loading={this.state.loading} help="Use this form to update your basic information.">
              <div className="form-group">
                <label htmlFor="firstName">First name</label>
                <input type="text" className="form-control" id="firstName" value={this.state.firstName} onChange={this.updateField} />
              </div>
              <div className="form-group">
                <label htmlFor="lastName">Last Name</label>
                <input type="text" className="form-control" id="lastName" value={this.state.lastName} onChange={this.updateField} />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input type="text" className="form-control" id="email" value={this.state.email} onChange={this.updateField} />
              </div>
              <div className="form-group">
                <label htmlFor="timezone">Timezone</label>
                <select className="form-control" id="timezone" value={this.state.timezone} onChange={this.updateField}>
                <option value="America/Puerto_Rico">America/Puerto_Rico</option>
                <option value="America/New_York">America/New_York</option>
                <option value="America/Chicago">America/Chicago</option>
                <option value="America/Denver">America/Denver</option>
                <option value="America/Phoenix">America/Phoenix</option>
                <option value="America/Los_Angeles">America/Los_Angeles</option>
                <option value="America/Anchorage">America/Anchorage</option>
                <option value="Pacific/Honolulu">Pacific/Honolulu</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="remember">Journal Reminders</label>
                <select className="form-control" value={this.state.journalGoodReminder} id="journalGoodReminder" onChange={this.updateField}>
                  <option value="none">Good Events - None</option>
                  <option value="daily">Good Events - Daily</option>
                  <option value="weekly">Good Events - Weekly</option>
                </select>
                <select className="form-control" value={this.state.journalPurposeReminder} id="journalPurposeReminder" onChange={this.updateField}>
                  <option value="none">Purpose Events - None</option>
                  <option value="daily">Purpose Events - Daily</option>
                  <option value="weekly">Purpose Events - Weekly</option>
                </select>

                <p className="text-small small">If selected, these will send you reminders about journal entries you made in these categories</p>
              </div>
              <div className="form-group">
                <button className="btn btn-block btn-primary" onClick={this.updateProfile}>Save Profile</button>
                <button className="btn btn-block btn-success" onClick={this.toggleChangePasswordModal}>Change Password</button>
              </div>
              <div className="form-group">
                <div>Last logged in on {moment(this.state.lastLogin).format("MM/DD/YYYY")}</div>
                <div>Account created on {moment(this.state.created).format("MM/DD/YYYY")}</div>
                <div>Last updated on {moment(this.state.updated).format("MM/DD/YYYY")}</div>
              </div>
    
              <Modal show={this.state.showChangePasswordModal} onHide={this.toggleChangePasswordModal}>
                <Modal.Header closeButton={true}>
                  <Modal.Title>Change Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>Enter your new password and confirm it below. Changing your password will log you out and you will have to log back in.</p>
                  <div className="form-group">
                    <label htmlFor="password">Enter your new password</label>
                    <PasswordInput id="password" value={this.state.password} onChange={this.updateField} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="confirmPassword">Confirm your new password</label>
                    <PasswordInput id="confirmPassword" value={this.state.confirmPassword} onChange={this.updateField} />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button className="btn btn-block btn-primary" onClick={this.changePassword}>Change Password</button>
                  <button className="btn btn-block btn-secondary" onClick={this.toggleChangePasswordModal}>Nevermind</button>
                </Modal.Footer>
              </Modal>
            </Card>
          </div>
        </div>
      </Screen>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private toggleChangePasswordModal(){
    this.setState({ showChangePasswordModal: !this.state.showChangePasswordModal});
  }

  private setup(){
    this.setState({loading: true}, async () => {
      try{
        const userResult = await UserAPI.getUserProfile();
        const ns: any = this.state;
        const profile = userResult.body.data;
        ns.loading = false;
        ns.created = moment(profile.created);
        ns.lastLogin = moment(profile.lastLogin);
        ns.updated = moment(profile.updated);
        ns.firstName = profile.firstName;
        ns.lastName = profile.lastName;
        ns.email = profile.email;
        ns.timezone = profile.timezone;
        ns.journalGoodReminder = profile.journalGoodReminder;
        ns.journalPurposeReminder = profile.journalPurposeReminder;
        this.setState(ns);
      }catch(error){
        this.setState({loading: false});
      }
    })
  }

  private updateProfile(){
    const data = {
      firstName: this.state.firstName.trim(),
      lastName: this.state.lastName.trim(),
      email: this.state.email.trim(),
      timezone: this.state.timezone.trim(),
      journalGoodReminder: this.state.journalGoodReminder,
      journalPurposeReminder: this.state.journalPurposeReminder,
    };
    if(data.firstName === "" || data.lastName === "" || data.email === "" || data.timezone === ""){
      return error("All fields are required.");
    }
    this.setState({ loading: true }, async () => {
      try{
        await UserAPI.updateUserProfile(data);
        success("Profile updated!");
        this.setState({ loading: false});
      }catch(err){
        error("Could not save your profile. Try again or contact support.");
        this.setState({ loading: false });
      }
    });
  }

  private changePassword(){
    const data = {
      password: this.state.password.trim(),
      confirmPassword: this.state.confirmPassword.trim(),
    };
    if(data.password !== data.confirmPassword || data.password === ""){
      return error("Password cannot be blank and both the password and confirm password fields must match.");
    }
    this.setState({ loading: true }, async () => {
      try{
        await UserAPI.updateUserProfile(data);
        success("Password updated!");
        await UserAPI.logoutUser();
        this.props.userActions.logoutUser();
        window.localStorage.clear();
        document.cookie.split(";").forEach((c) => {
          document.cookie = c
            .replace(/^ +/, "")
            .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });
        this.props.history.push("/login");
      }catch(err){
        error("Could not change your password. Try again or contact support.");
        this.setState({ loading: false });
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile) as any);
import * as React from "react";
import moment from "moment";
import DayPickerInput from "react-day-picker/DayPickerInput";

interface IDatePickerProps {
  onDateSaved: (newDate: moment.Moment) => any;
  date: moment.Moment;
  style?: any;
}

interface IDatePickerState {
  loading: boolean;
  date: moment.Moment;
}

class DatePicker extends React.Component<IDatePickerProps, IDatePickerState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      date: moment()
    };

    this.handleDateChanged = this.handleDateChanged.bind(this);
  }

  public componentDidMount(){
    if(this.props.date && moment.isMoment(this.props.date)){
      this.setState({date: this.props.date});
    }
  }
  
  public static componentWillReceiveProps(nextProps: any){
    if(nextProps.date && moment.isMoment(nextProps.date)){
      return {date: nextProps.date};
    }
    return null;
  }

  public render() {
    return (
      // <DayPickerInput
      //   onDayChange={this.handleDateChanged}
      //   value={this.state.date.format("MM/DD/YYYY")}
      //   formatDate={formatDate}
      //   parseDate={parseDate}
      //   format="M/D/YYYY" />

      <DayPickerInput 
        style={this.props.style ? this.props.style: {}}
        onDayChange={this.handleDateChanged} 
        value={this.state.date.format("MM/DD/YYYY")}
        format="MM/DD/YYYY"
      />
    );
  }

  private handleDateChanged(newDate: string | Date){
    if(newDate === undefined || !newDate){
      return;
    }
    const d = moment(newDate);
    if(d.isValid()){
      return this.setState({
        date: d
      }, () => {
        this.props.onDateSaved(this.state.date);
      });
    }
    return null;
  }

}

export default DatePicker;
import { makeCall } from "./client";
export class RememberJarAPI {

    /**
     * Get the entries
     * @param start 
     * @param end 
     * @param otherData 
     * @returns 
     */
    public getEntries(start: string, end: string, otherData: any = {}): Promise<any> {
      const data = {
        ...otherData,
        start,
        end,
      };
      return makeCall("get", `remember/`, data);
    }

    /**
     * Create a new entry
     * @param data 
     * @returns 
     */
    public createEntry(data: any): Promise<any> {
        return makeCall("post", `remember/`, data);
      }

    /**
     * Get a single entry
     * @param entryId 
     * @param otherData 
     * @returns 
     */
    public getEntry(entryId: number, otherData: any = {}): Promise<any> {
      const data = {
        ...otherData,
      };
      return makeCall("get", `remember/${entryId}`, data);
    }

    /**
     * Delete an entry
     * @param entryId 
     * @param otherData 
     * @returns 
     */
    public deleteEntry(entryId: number, otherData: any = {}): Promise<any> {
        const data = {
          ...otherData,
        };
        return makeCall("delete", `remember/${entryId}`, data);
      }

      /**
       * Update an entry
       * @param entryId 
       * @param data 
       * @returns 
       */
      public updateEntry(entryId: number, data: any): Promise<any> {
        return makeCall("patch", `remember/${entryId}`, data);
      }

      /**
       * Helper for visibility display
       * @param input 
       * @returns 
       */
      public translateVisibility(input: string): string {
        if(input === "shared"){
            return "Shared";
        }
        return "Private";
      }

      /**
       * Helper for type display
       * @param input 
       * @returns 
       */
      public translateType(input: string): string {
        if(input === "good_event"){
            return "Good Event";
        }
        return "Purpose";
      }
}
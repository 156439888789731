import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Modal, Tab, Tabs } from "react-bootstrap";

import Card from "src/components/structure/Card";
import Screen from "src/components/structure/Screen";
import { ToolsAPI, UserAPI } from "src/api";

import { ShareRow } from "./ShareRow";
import { error, success } from "src/components/structure/Alert";
import ToolDisplay from "./ToolDisplay";

interface IToolViewProps {
  userState: any;
  match: any;
}

interface IToolViewState {
  loading: boolean;
  toolType: string;
  submissionId: number;
  submissionData: any;
  connections: any[];

  showDeleteModal: boolean;
}

class ToolView extends React.Component<IToolViewProps, IToolViewState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      toolType: this.props.match.params.toolType,
      submissionId: this.props.match.params.submissionId,
      submissionData: {},
      connections: [],

      showDeleteModal: false,
    };

    this.updateField = this.updateField.bind(this);
    this.setup = this.setup.bind(this);
    this.showTool = this.showTool.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.deleteSubmission = this.deleteSubmission.bind(this);
  }

  componentDidMount(){
    this.setup();
  }

  public render() {
    return (
      <Screen fileName="ToolView.tsx">
        <Card title="Manage Submission" loading={this.state.loading} help="">
          <Tabs defaultActiveKey="view" id="viewSubmissionTabs" className="mb-3">
            <Tab eventKey="view" title="View Submission">
              {this.showTool()}
            </Tab>
            <Tab eventKey="share" title="Sharing">
              <div className="row">
                <div className="col-12 col-lg-6 offset-3">
                  {this.state.connections.map((c) => {
                    return (
                      <ShareRow 
                        key={c.id} 
                        connection={c} 
                        submissionId={this.state.submissionId} 
                        toolType={this.state.toolType} 
                        userId={this.props.userState.user.id}
                        isShared={c.isShared}
                        shareId={c.shareId} />
                    )
                  })}
                </div>
              </div>
            </Tab>
            <Tab eventKey="manage" title="Manage">
              <div className="row">
                <div className="col-lg-3 col-12">
                  <Card>
                    <p>Delete this submission and remove all access to the data. This is not reversible!</p>
                    <button className="btn btn-block btn-danger" onClick={this.toggleDeleteModal}>Delete this Submission</button>
                  </Card>
                </div>
              </div>

              <Modal show={this.state.showDeleteModal} onHide={this.toggleDeleteModal} dialogClassName="modal-medium" >
                <Modal.Header closeButton={true}>
                  <Modal.Title>Are You Sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Are you sure you want to delete this submission? It will delete the data, remove all shares, and cannot be undone.
                </Modal.Body>
                <Modal.Footer>
                  <button className="btn btn-block btn-danger" onClick={this.deleteSubmission}>Yes, Delete This Submission</button>
                  <button className="btn btn-block btn-secondary" onClick={this.toggleDeleteModal}>Nevermind</button>
                </Modal.Footer>
              </Modal>
            </Tab>
          </Tabs>
        </Card>
      </Screen>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private showTool(){
    switch(this.state.toolType){
      case "fiveColumnThoughtRecord":
        return <ToolDisplay toolType={this.state.toolType} userId={this.props.userState.user.id} mode="view" submissionData={this.state.submissionData} />
      case "spiritualFitnessInventory":
        return <ToolDisplay toolType={this.state.toolType} userId={this.props.userState.user.id} mode="view" submissionData={this.state.submissionData} />
      case "therapyAssessment":
        return <ToolDisplay toolType={this.state.toolType} userId={this.props.userState.user.id} mode="view" submissionData={this.state.submissionData} />
      default:
        return null;
    }
  }


  private setup(){
    this.setState({loading: true}, async () => {
      try{
        const submissionResult = await ToolsAPI.getSubmissionById(this.state.toolType, this.state.submissionId);
        const submissionData: any = {
          ...submissionResult.body.data,
          submissionId: this.state.submissionId,
          toolType: this.state.toolType,
        }
        const sharesResult = await ToolsAPI.getSubmissionShares(this.state.toolType, this.state.submissionId);
        const shares = sharesResult.body.data;
        
        const connectionsResult = await UserAPI.getUserConnections();
        const connections: any = [];
        for(const c of connectionsResult.body.data){
          if(c.status === "accepted"){
            // see if it's been shared yet
            for(const s of shares){
              c.isShared = false;
              c.shareId = 0;
              if(s.userId === c.providerId){
                c.isShared = true;
                c.shareId = s.id;
              }
            }
            connections.push(c);
          }
        }
        this.setState({loading: false, submissionData, connections})
      }catch(error){
        this.setState({loading: false});
      }
    })
  }

  private toggleDeleteModal(){
    this.setState({showDeleteModal: !this.state.showDeleteModal});
  }

  private deleteSubmission(){
    this.setState({ loading: false }, async () => {
      try{
        await ToolsAPI.deleteSubmissionById(this.state.toolType, this.state.submissionId);
        success("Submission deleted!");
        window.location.href = "/tools";
      }catch(err){
        error("Could not delete that submission. Please contact support.");
        this.setState({ loading: false });
      }
    })
  }

}


const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ToolView) as any);
import * as React from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { ShareRow } from "./ShareRow";


interface INewToolShareModalProps {
  show: boolean;
  userId: number;
  toolType: string;
  submissionId: number;
  connections: any;
  onClose: any;
}

interface INewToolShareModalState {
  loading: boolean;
}

class NewToolShareModal extends React.Component<INewToolShareModalProps, INewToolShareModalState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false
    };

    this.updateField = this.updateField.bind(this);
  }

  componentDidMount(){
  }

  public render() {
    return (
      <div>
        <Modal show={this.props.show} onHide={this.props.onClose} dialogClassName="modal-medium" >
          <Modal.Header closeButton={true}>
            <Modal.Title>Share With a Connection</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.props.connections.map((c: any) => {
              if(c.status !== "accepted"){
                return null;
              }
              return <ShareRow key={c.id} connection={c} userId={this.props.userId} submissionId={this.props.submissionId} toolType={this.props.toolType} />
            })}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-block btn-primary" onClick={() => window.location.href="/tools"}>I'm done!</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(NewToolShareModal);